/*! reset sanitizeを読み込み その上で必要な要素をリセットします */
@import "../lib/_sanitize";


/* 以下 wrap */
:root {
  font-size: 10px;
  font-size: 62.5%;
}

/* !overflow については sanitize で 指定されているため解除 */
html {
  font-size: 10px;
  font-size: 62.5%;
  word-break: break-all;
  overflow: initial;
}

oi,li {
  list-style-type: none;
}

input{
  outline:none;
}

input[type="text"]{
  outline:inherit;
}


button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
}
