.ac {
text-align:center !important;
}

.al{
text-align:left !important;
}

.ar{
  text-align:right !important;
}

.fc{
  clear:both !important;
}

.fl{
  float:left !important;
}

.fr{
  float:right !important;
}


@include mqSP {
  .sp-ac {
    text-align:center !important;
  }

  .sp-al{
   text-align:left !important;
  }

  .sp-ar{
    text-align:right !important;
  }

  .sp-fc{
    clear:both !important;
  }

  .sp-fl{
    float:left !important;
  }

  .sp-fr{
    float:right !important;
  }
}

.disp-hide{
  display:none;
}

.disp-pre{
  overflow:hidden;
}

.disp-show{
  display:initial;
}

@for $i from 0 through 10 {
  .pr-#{$i} {padding-right:#{$i}% !important;}
  .pl-#{$i} {padding-left:#{$i}% !important;}
  .pt-#{$i} {padding-top:#{$i}% !important;}
  .pb-#{$i} {padding-bottom:#{$i}% !important;}
  .mr-#{$i} {margin-right:#{$i}% !important;}
  .ml-#{$i} {margin-left:#{$i}% !important;}
  .mt-#{$i} {margin-top:#{$i}% !important;}
  .mb-#{$i} {margin-bottom:#{$i}% !important;}
}


@for $i from 1 through 10 {
	@for $j from $i through 10 {
	  $cat : ($i *100 / $j );
	  $cat-w : $cat +'%';
	  .width-#{$i}-#{$j}{ width: #{$cat-w} ;box-sizing: border-box;} 
	}
}

@for $i from 1 through 10 {
  @for $j from $i through 10 {
    $cat : ($i *100 / $j );
    $cat-w : $cat +'%';
    .width-#{$i}-#{$j}-pc{ width: #{$cat-w} ;box-sizing: border-box ;p{text-align: center;font-size: medium;}}
  }
}

@include mqSP {
  @for $i from 1 through 10 {
    @for $j from $i through 10 {
      $cat : ($i *100 / $j );
      $cat-w : $cat +'%';
      .width-#{$i}-#{$j}-pc{ width:100%}
    }
  }
}


@include mqSP {
  @for $i from 1 through 10 {
    @for $j from $i through 10 {
      $cat : ($i *100 / $j );
      $cat-w : $cat +'%';
      .width-#{$i}-#{$j}-sp{ width: #{$cat-w} ;p{text-align: center;font-size: medium;box-sizing: border-box;}}
    }
  }
}




.shadow{
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #dedede;
}

.radius{
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.no-line-style{
  list-style-type : none !important;
}

