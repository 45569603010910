.pagetop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor:pointer;
    z-index:1000;
    a{
	    @include fzauto(12);    
	    background: #5A4E4E;
	    text-decoration: none;
	    color: #fff;
	    width: 92px;
        padding: 19px 0;
	    text-align: center;
	    display: block;
	    border-radius: 5px;

	   &:hover{
		    text-decoration: none;
		    background: #999;
	   }
    }
}
