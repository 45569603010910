.panel{
	background-color: white;
    margin: 0 5px;
    box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
    padding-bottom: 19px;
    margin: 45px 0;
	@extend .clearfix;

	 .title{
	 	border-bottom:1px solid #c0c0c0 ;
		padding: 12px 0 ;
	 	font-size:130%;
		.login{
			text-align: center;
		}
		.shops-login{
			text-align: right;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 13px;
		a.link {
			text-decoration: underline;
    	color: #3f48cc;
		}
		}
	 }


		 ul.contact-title{
			 display: table;
			 table-layout: fixed;
			 text-align: center;
			 width: 80%;
			 margin: 0 auto;
			 padding: 20px 0px;
			 li{
				 display: table-cell;
	  	 	 vertical-align: middle;
			 }
		 }

	.right{
		width:50%;
		float:left;

	    @include mqSP {
			width:96%;
	        margin: 0 2%;
	        float:none;
	        padding-bottom:22px;

	        img.fb {
			    width: 80%;
			    margin-top: 2%;
			    margin-bottom: 2%;
				}
	    }

			.mail-title{
				@include font-set(5,16,1);
				text-align: center;
			}

			.contact-dtl{
				@include mqSP{
					width: 100%;
				};
			}
	}

	.left{
		width:50%;
		float:left;
		padding-left: 30px;
		border-right: 1px solid #c0c0c0;
		@include mqSP{
			border-right: none;
			padding-left: 0px;
		};

		.fb-title{
			@include font-set(5,16,1);
			text-align: center;
			padding-bottom: 15px;
			@include mqSP{
				padding-top: 5%;
			};
		}

		.or{
			padding-top: 10px;
		}

		img.fb-register{
			width: 80%;
			padding-top:20px;
			@include mqSP{
				width: 100%;
				padding-top: 0px;
			};
		}


	    @include mqSP {
			width:96%;
	        margin: 0 2%;
	        float:none;
					a.link {
						text-decoration: underline;
			    	color: #3f48cc;
					}
	    }
	}
}
