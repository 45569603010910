//http://weboook.blog22.fc2.com/blog-entry-408.html

.dropmenu{
  *zoom: 1;
  list-style-type: none;
  width: 960px;
  margin: 5px auto 30px;
  padding: 0;
  &:before {
    content: "";
    display: table;
  }
  &:after{
    content: "";
    display: table;
    clear: both;
  }
  > li > a {
    &:after{
      content: "▼";
      color:#E19546;
      right: 8px;
      position: absolute;
    }
  }
  li {
    position: relative;
    width: 20%;
    float: left;
    margin: 0;
    padding: 0;
    text-align: center;
     a {
       display: block;
       margin: 0;
       padding: 15px 0 11px;
       background: #8a9b0f;
       background:  white;
       color:black;
       font-size: 14px;
       line-height: 1;
       text-decoration: none;
     }
     ul {
         list-style: none;
         position: absolute;
         z-index: 9999;
         width:100%;
         top: 100%;
         left: 0;
         margin: 0;
         padding: 0;
          li
          {
            width: 100%;
             a
             {
               padding: 13px 15px;
               border-top: 1px solid #c0c0c0;
//               background: #6e7c0c;
               text-align: left;

               background:  white;
               color:black;
               &:hover{
 //                background: #616d0b;
               }
             }
            &:hover {
              &>a{
  //              background: #6e7c0c;
              }
            }
          }
       }
     }

     &.pattern1 {
       li {
         ul{
           display: none;
         }
         &:hover {
           ul{
             display: block;
           }
         }
       }
     }

     &.pattern2 {
       li {
         ul {
           li {
             overflow: hidden;
             height: 0;
             transition: .2s;
           }
         }
         &:hover {
           ul {
             li {
               overflow: visible;
               height: 38px;
             }
           }
         }
       }
     }

     &.pattern3 {
       li {
         ul {
           opacity: 0;
           top: 50%;
           visibility: hidden;
           transition: .5s;
         }
         &:hover {
           ul {
             top: 100%;
             visibility: visible;
             opacity: 1;
           }
         }
       }
     }

     &.pattern4 {
       li {
         ul {
           opacity: 0;
           top: 50%;
           visibility: hidden;
           transition: .5s;
         }
         &:hover {
           ul {
             top: 100%;
             visibility: visible;
             opacity: 1;
           }
         }
       }
     }

     &.pattern5 {
       ul {
         overflow: hidden;
         width: 0;
         transition: .5s;
       }
       li {
         &:hover {
           ul{
             width: 100%;
             li {
               a {
                white-space: nowrap;
               }
             }
           }
         }
       }
     }

     &.pattern6 {
       li {
         &> li{
           perspective: 400px;
         }
         ul {
           visibility: hidden;
           transform: rotateX(-90deg);
           transform-origin: 50% 0;
           transition: .3s;
         }
         &:hover {
           ul {
             visibility: visible;
             transform: rotateX(0);
           }
         }
       }
     }

     &.pattern7 {
       li {
         ul{
           visibility: hidden;
           perspective: 400px;
           li{
             transform: rotateY(90deg);
             transform-origin: 50% 0;
             transition: .3s;
           }
         }
         &:hover {
           ul {
            visibility: visible;
           }
           li {
            transform: rotateY(0);
           }
         }
       }
       ul {
         li {
           transform: rotateY(90deg);
           transform-origin: 50% 0;
           transition: .3s;
           &:nth-child(2) {
             transition-delay: .1s;
           }
           &:nth-child(3) {
             transition-delay: .2s;
           }
           &:nth-child(4) {
             transition-delay: .3s;
           }
           &:nth-child(5) {
             transition-delay: .4s;
           }
         }
       }
     }

     &.pattern8 {
       ul {
         visibility: hidden;
         perspective: 400px;
         li {
           transform: rotateX(-90deg);
           transform-origin: 50% 0;
           transition: .1s;
         }
       }
       li {
         &:hover {
           ul {
            visibility: visible;
            li {
              transform: rotateX(0);
              &:nth-child(1) {
                transition-delay: 0s;
              }
              &:nth-child(2) {
                transition-delay: .1s;
              }
              &:nth-child(3) {
                transition-delay: .2s;
              }
              &:nth-child(4) {
                transition-delay: .3s;
              }
           }
         }
       }
       ul {
         li {
           &:nth-last-of-type(1) {
             transition-delay: 0s;
           }
           &:nth-last-of-type(2) {
             transition-delay: .1s;
           }
           &:nth-last-of-type(3) {
             transition-delay: .2s;
           }
           &:nth-last-of-type(4) {
             transition-delay: .3s;
           }
         }
       }
     }
   }
 }
