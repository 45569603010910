.nav-paginate {
    text-align: center;
    font-size: 0rem;
    margin-top: 60px;

}
 .nav-paginate .wp-pagenavi {
    position: relative;
    display: inline-block;
    padding-bottom: 25px;
    width: 100%;
}

.nav-paginate .wp-pagenavi span, .nav-paginate .wp-pagenavi a {
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    font-size: 1.4rem;
    line-height: 28px;
    margin: 0 25px;
    vertical-align: middle;


    @include mqSP {
        margin: 0 2%;
    }

}


.nav-paginate .wp-pagenavi span, .nav-paginate .wp-pagenavi a.number {
    margin: 0 20px;
}


.nav-paginate .previouspostslink{
     background: url(".assets/images/common/button-arrow2-l.png");
    padding: 11px 11px;
    background-size: 100% 100%;
    font-size: 1.4rem;
    line-height: 28px;
    margin-top: 20px;

}

.nav-paginate .nextpostslink{
     background: url(".assets/images/common/button-arrow2-r.png");
      padding: 11px 11px;
    background-size: 100% 100%;
    font-size: 1.4rem;
    line-height: 28px;
    margin-top: 20px;
   
}
