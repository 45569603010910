/*! @include として利用するものを記述します */
@mixin base-color($pattern){
  @if $pattern == 1      {background-color:#50463c;} 
  @elseif  $pattern == 2 {background-color:#f08228;} 
  @elseif  $pattern == 3 {background-color:#e60f19;} 
  @elseif  $pattern == 4 {background-color:#82be28;} 
  @elseif  $pattern == 5 {background-color:#74726C;} 
  @elseif  $pattern == 6 {background-color:#f5f5f0;} 
  @elseif  $pattern == 7 {background-color:#e1e1dc;} 
  @elseif  $pattern == 8 {background-color:#fffbd4;} 
  @else                  {background-color:#ffffff;}
}

@mixin font-color($pattern) {
  @if $pattern == 1      {color:#50463c;} 
  @elseif  $pattern == 2 {color:#f08228;} 
  @elseif  $pattern == 3 {color:#e60f19;} 
  @elseif  $pattern == 4 {color:#82be28;} 
  @elseif  $pattern == 5 {color:#74726C;} 
  @elseif  $pattern == 6 {color:#f5f5f0;} 
  @elseif  $pattern == 7 {color:#e1e1dc;} 
  @elseif  $pattern == 8 {color:#fffbd4;} 
  @else                  {color:#ffffff;}
}

@mixin fz($size: 14) {
    font-size: $size + px;
    font-size: ($size / 10)  + rem;

    @include mqSM {
      font-size: $size * 0.75 + px;
      font-size: ($size * 0.8 / 10)  + rem;


    font-size: $size + px;
    font-size: ($size / 10)  + rem;
    }

    @include mqXS {
      font-size: $size * 0.65 + px;
      font-size: ($size * 0.65 / 10)  + rem;


    font-size: $size + px;
    font-size: ($size / 10)  + rem;
    }
}

@mixin fzauto($size: 14) {
    font-size: $size + px;
    font-size: ($size / 10)  + rem;
    line-height: $size * 1.5  + px;
    line-height: ($size * 1.5 / 10)  + rem;

    @include mqSM {
      font-size: $size * 0.8 + px;
      font-size: ($size * 0.8 / 10)  + rem;
      line-height: $size * 1.5 * 0.8  + px;
      line-height: ($size * 1.5 * 0.8  / 10)  + rem;


    font-size: $size + px;
    font-size: ($size / 10)  + rem;
    line-height: $size * 1.5  + px;
    line-height: ($size * 1.5 / 10)  + rem;

    }

    @include mqXS {
      font-size: $size * 0.65 + px;
      font-size: ($size * 0.65 / 10)  + rem;
      line-height: $size * 1.5 * 0.65 + px;
      line-height: ($size * 1.5 * 0.65  / 10)  + rem;


    font-size: $size + px;
    font-size: ($size / 10)  + rem;
    line-height: $size * 1.5  + px;
    line-height: ($size * 1.5 / 10)  + rem;

    }
}


@mixin fzauto-just($size: 14) {
    font-size: $size + px;
    font-size: ($size / 10)  + rem;
    line-height: $size * 1.6  + px;
    line-height: ($size * 1.6 / 10)  + rem;

    @include mqSM {
      font-size: $size * 0.8 + px;
      font-size: ($size * 0.8 / 10)  + rem;
      line-height: $size * 1.8 * 0.8  + px;
      line-height: ($size * 1.8 * 0.8  / 10)  + rem;


    font-size: $size + px;
    font-size: ($size / 10)  + rem;
    line-height: $size * 1.6  + px;
    line-height: ($size * 1.6 / 10)  + rem;
    }

    @include mqXS {
      font-size: $size * 0.65 + px;
      font-size: ($size * 0.65 / 10)  + rem;
      line-height: $size * 1.8 * 0.65 + px;
      line-height: ($size * 1.8 * 0.65  / 10)  + rem;

      
    font-size: $size + px;
    font-size: ($size / 10)  + rem;
    line-height: $size * 1.6  + px;
    line-height: ($size * 1.6 / 10)  + rem;
    }
}

@mixin f-type($pattern){
  
}


@mixin font-set-auto-just($pattern , $size , $type) {
  @include font-color($pattern);
  @include fzauto-just($size);
  @include f-type($type);
}

@mixin font-set-auto($pattern , $size , $type) {
  @include font-color($pattern);
  @include fzauto($size);
  @include f-type($type);
}


@mixin font-set($pattern , $size , $type) {
  @include font-color($pattern);
  @include fzauto-just($size);
  @include f-type($type);
}


@mixin middleline($height, $ie: 0){
    height: $height + px;
    line-height: $height + px;
    &:not(:target){
        line-height: $height + $ie + px + \9;
    }
    :root & {
        line-height: $height + $ie + px + \9;
    }
}


@mixin text-shadow-black($opacity: .4){
  text-shadow: 0 1px 0 rgba(#000, $opacity);
}

@mixin text-shadow-white($opacity: 1){
  text-shadow: 0 1px 0 rgba(#FFF, $opacity);
}

@mixin background-width-act($src,$extention){
    background-image: url("#{$src}.#{$extention}");
    &:hover,&.act{
      background-image: url("#{$src}_act.#{$extention}");
    }
}


@mixin position-absolue-top-left($top:0 , $left:0){
  position:absolute;
  top:$top;
  left:$left;
}
@mixin position-absolue-top-right($top:0 , $right:0){
  position:absolute;
  top:$top;
  right:$right;
}

@mixin position-absolue-bottom-left($bottom:0 , $left:0){
  position:absolute;
  bottom:$bottom;
  left:$left;
}

@mixin position-absolue-bottom-right($bottom:0 , $right:0){
  position:absolute;
  bottom:$bottom;
  right:$right;
}




/*
  画面切り替え対応
*/
@mixin mqXL {
  //$widthXL（init : 1280px）以上の場合
  @media (min-width: $widthXL) {
    @content;
  }
}

@mixin mqLG {
  //$widthLG（init : 1024px）〜$widthXL-1（init : 1279px）の場合
  @media (min-width: $widthLG) and (max-width: ($widthXL - 1)) {
    @content;
  }
}

@mixin mqMD {
  //$widthMD（init : 768px）〜$widthLG-1（init : 1023px）の場合
  @media (min-width: $widthMD) and (max-width: ($widthLG - 1)) {
    @content;
  }
}

@mixin mqSM {
  //$widthSM（init : 568px）〜$widthMD-1（init : 767px）の場合
  @media (min-width: $widthSM) and (max-width: ($widthMD - 1)) {
    @content;
  }
}

@mixin mqXS {
  //$widthSM-1（init : 567px）以下の場合
  @media (max-width: ($widthSM - 1)) {
    @content;
  }
}


@mixin mqPC{
  @include mqXL {
     @content;
  }
  @include mqLG {
     @content;
  }
  @include mqMD {
     @content;
  }
}

@mixin mqSP{
  @include mqSM {
      @content;
  }
  @include mqXS {
       @content;
  }
}

/**
  KeyFrame
**/
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}


@mixin animation($animation-name) {
  -webkit-animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

@mixin transform($trans-form) {
    -webkit-transform: $trans-form ;
    -moz-transform: $trans-form; 
    transform: $trans-form ; 
}


/*
  border に $radius の丸みをいれる
*/
@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  -opera-border-radius: $radius;
}


@mixin border-images($url , $top ,$left , $right ,$bottom) {
  -moz-border-image: url($url) $top $left $right $bottom repeat;
  -webkit-border-image: url($url) $top $left $right $bottom repeat;
  -o-border-image: url($url) $top $left $right $bottom repeat;
  border-image: url($url) $top $left $right $bottom fill repeat;
}


/*
  boxに指定した shadow を入れる
*/
@mixin box-shadow($h-shadow, $v-shadow, $blur, $color, $spread: '', $inset: '') {
  -webkit-box-shadow: $h-shadow $v-shadow $blur $spread $color $inset;
  -moz-box-shadow: $h-shadow $v-shadow $blur $spread $color $inset;
  box-shadow: $h-shadow $v-shadow $blur $spread $color $inset;
}



/*
  boxに指定した background を入れる
*/
@mixin box-base-bggradiet-left-right-title($l-rgb, $r-rgb) {
  background: $l-rgb; /* Old browsers */
  background: -moz-linear-gradient(left,  $l-rgb 0%, $r-rgb 93%, $r-rgb 100%); /* FF3.6-15 */
  background: -webkit-linead-gradient(left,  $l-rgb 0%, $r-rgb 93%, $r-rgb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  $l-rgb 0%, $r-rgb 93%, $r-rgb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$l-rgb', endColorstr='$r-rgb',GradientType=1 ); /* IE6-9 */
}

/*
  boxに指定した background を入れる
*/
@mixin box-base-bggradiet-left-right($l-rgb, $r-rgb) {
  background: $l-rgb; /* Old browsers */
  background: -moz-linear-gradient(left,  $l-rgb 0%, $r-rgb 100%); /* FF3.6-15 */
  background: -webkit-linead-gradient(left,  $l-rgb 0%, $r-rgb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  $l-rgb 0%, $r-rgb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$l-rgb', endColorstr='$r-rgb',GradientType=1 ); /* IE6-9 */
}

/*
  boxに指定した background を入れる
*/
@mixin box-base-bggradiet-left-center-right($l-rgb, $c-rgb, $r-rgb) {
  background: $l-rgb; /* Old browsers */
  background: -moz-linear-gradient(left,  $l-rgb 0%, $c-rgb 50%,$r-rgb 100%); /* FF3.6-15 */
  background: -webkit-linead-gradient(left,  $l-rgb 0%, $c-rgb 50%,$r-rgb 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  $l-rgb 0%,$c-rgb 50%, $r-rgb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$l-rgb', endColorstr='$r-rgb',GradientType=1 ); /* IE6-9 */
}




