@include keyframes(menu-deg1){
  0% { transform: rotate(0deg); }
  25% { transform: rotate(8deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-8deg); }
  100% { transform: rotate(0deg); }
}

@include keyframes(menu-deg2){
  0% { transform: rotate(0deg); }
  25% { transform: rotate(2deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
}

@include keyframes(menu-deg3){
  0% { transform: rotate(0deg); }
  25% { transform: rotate(8deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-8deg); }
  100% { transform: rotate(0deg); }
}

@include keyframes(moving1){
  0% { transform: translateX(0) rotate(0deg) left(60%);  }
  100% { transform: translateX(100%) rotate(0deg); }
}

@include keyframes(rolling1){
  0% { transform: translateX(0) rotate(0deg);  }
  100% { transform: translateX(360px) rotate(1440deg); }
}

@include keyframes(rolling2){
  0% { transform: translateX(0) rotate(0deg);  }
  50% { transform: translateX(180px) rotate(720deg); }
  100% { transform: translateX(360px) rotate(1440deg); }
}

@include keyframes(fadeIn) {
    0% {opacity: 0}
    100% {opacity: 1}
}


@include keyframes(fadeInLater) {
    0% {opacity: 0}
    50% {opacity: 0}
    100% {opacity: 1}
}

