ul.category.menu{
	@extend .inline-center;
	li {
	    margin: 0px 6px;
	    width: 100px;
	    height: 170px;
	    vertical-align: top;

	    .image{
			@extend .thumbnail.circle-border;
			width: 100px;
	    	height: 100px;
			img{
				@extend .animate.hover-route;
			}
	    }
		p{
		    display: table-cell;
		    height: 42px;
		    padding-top:12px;
		    vertical-align: middle;
		}
	}
}

.search-top-area{
	.left{
		ul.category.menu li{
		    height: 277px;
		}

	}
}

#categorylefttab{
	ul.category.menu{
		li {
			>div{
			    display: inline-block;
			}

		    text-align: left;
			width: calc(100% - 12px);
			height: inherit;

			.image{
			    margin: 0 auto;
					margin-right: 25px;
			}

			p,h1 {
			    margin-left: 5px;
			    display: inline-block;
				position: relative;
			}
		}
	}
}

li p.toggle-lines {
	display: table-cell !important;
    padding-right: 15px;
}
li .toggle-lines-sibling {
	position: relative;
	float: right;

	> div {
		position: absolute;
		top: -20px;
		right: 0px;
	}
}

.category-menu{
  .menus-menu{
  	display: inline-block;
    width: 29%;
    min-width: 200px;
    vertical-align: top;
    margin: 0 2%;
    text-align:left;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .image{
    display: inline-block;
    width: 65px;
    height: 65px;
    border: 5px solid WHITE;
    border-radius: 50%;
    -moz-border-image: 50%;
    -webkit-border-image: 50%;
    box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
	  img{
	    width: 100%;
	    border-radius: 50%;
	    -moz-border-image: 50%;
	    -webkit-border-image: 50%;
	  }
  }
  .name{
  	  padding-left:12px;
  	  font-weight:bold;
      display: inline-block;
  }

  .menu{
    float:left;
    margin: 27px 0 0;
    .main-menu{
        text-align: left;
    	border-bottom:1px solid silver;
    	    padding-bottom: 10px;
	    margin-bottom: 10px;
    }
    .sub-menu{
		ul{
		    li{
	text-align: center;
    padding: 3px 12px;
    box-shadow: 0px 2px 4px #aaa;
    margin: 3px 2px;
    border-radius: 3px;
    float: left;
    background-color: #d4cfc1;
		    }

		}
	  }
  }
}
