.inline-center{
    text-align: center;
	> li{display:inline-block;}
	> div{display:inline-block;}
}

.inline-left{
	@extend .clearfix;
	> li{float:left;}
	> div{loat:left;}
}

.inline-right{
	@extend .clearfix;
	> li{float:right;}
	> div{loat:right;}
}