div.top-features{
    @include base-color(99);
    padding: 15px 0px;

    .title{
      width: 268px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      margin-top: 16px;
      margin-bottom: 21px;
    }
    ul {
      @extend .clearfix;
      font-size: 12px;
      display: inline-block;
      max-width: 650px;

      li{
        display: inline-block;
        vertical-align: middle;
        width: 30%;
        margin-left: 2%;
        img{
            vertical-align: initial;
        }
        p{
          display: inline-block;
          text-align:left;
          line-height : 2rem;
          width: 63%;
          margin-left:2%;

          @include mqSP {
            margin-left:0%;
            width: 100%;
          };
        }
      }
    }
}


.anchor{
  &.more{
    @include font-color(2);
    @include fzauto(14);
    width:100%;

    padding: 19px 0;
    display: inline-block;

    &:hover{
        text-decoration: underline;
    }
  }
}

.button{
  &.station{
    line-height:40px;
    height:40px;
    width: 300px;
    margin-top:60px;
    background-color:#F18930;
    color:white;
        border-radius: 2px;
    @include base-color(2);
  }
  &.feature{
    line-height:40px;
    height:40px;
    width: 75%;
    margin:20px auto;
    background-color:#F18930;
    color:white;
    border-radius: 2px;
    @include base-color(2);
  }
  &.review{
    @extend .feature
  }
  &.logout{
    line-height:40px;
    height:40px;
    width: 300px;
    margin-top:60px;
    background-color:#e3e1db;
    border-radius: 2px;
    box-shadow: 0 3px 0 #d3cebf;
    color: #74726C;
    @include mqSP {
      width:45%;
    }
  }
  &.withdraw{
    @extend .logout;
    margin-left: 100px;
    background-color: dimgray;
    color: white;
    @include mqSP {
      margin-left: 0;
    };
  }

  &.withdraw.station{
    @extend .logout;
    margin-left: 100px;
    color:white;
    border-radius: 2px;
    background-color:#F18930;
    @include base-color(2);
    @include mqSP {
      margin-left: 0;
    };
  }


  &.withdraw.w-sp-100{
    @include mqSP {
      width:100%;
    };
  }
}

.fa{
    margin-right: 3px;
}

#map{
 position:relative;
 text-align:center;


 image{
   text-align:center;
 }

 h3{
   text-align:left;
 }
 ul {
   li{
       text-align:center;
       padding: 3px 12px;
       box-shadow: 0px 2px 4px #aaa;
       margin: 3px 2px;
       border-radius: 3px;
       float:left;
       background-color:white;
   }

 }

 .map-area{
     position:absolute;
 }

 .map_1{
     h3{
       color:#56969b;
     }
     right: 0%;
     top: 0%;
     width: 22%;
 }

 .map_2{
		 h3{
			 color:#66b0b2;
		 }
		 right: 6%;
		 top: 28%;
		 width: 22%;
 }

 .map_3{
		h3{
			color:#71bfbb;
		}
		right: 6%;
		top: 69%;
		width: 25%;
 }

 .map_4{
		h3{
			color:#98d8d3;
		}
		left: 32%;
		top: 0%;
		width: 22%;
 }

 .map_5{
		h3{
			color:#dde788;
		}
		right: 17%;
		top: 100%;
		width: 30%;
 }

 .map_6{
		h3{
			color:#cfe283;
		}
		left: 11%;
		top: 32%;
		width: 22%;
 }

 .map_7{
		h3{
			color:#b8cc71;
		}
		left: 32%;
		top: 100%;
		width: 20%;
 }

 .map_8{
		h3{
			color:#9cbc50;
		}
		left: 3%;
		top: 90%;
		width: 25%;
 }
}

.search-title{
  text-align:left;
  margin-top:22px;
  padding-bottom:22px;
  border-top :1px solid #c0c0c0;
}

.search-top-area{
  @extend .clearfix;
  .left{
    float:left;
    width :calc(100% - 497px);
    overflow: hidden;
    position: relative;
    @include mqSP {
      width:100%;
    }

  }

  #categorylefttab{
      padding-top: 70px;
  }

  .right{
    width:480px;
    float:right;
    @include mqSP {
      width:100%;
    }

    .search-box{
      .title{
          background-color: white;
          border: 1px solid #c0c0c0;
          padding-top: 8px;
          padding-bottom: 9px;
          margin-top: 55px;
      }

       .dropmenu{
          width:100%;
          background-color:white;
          color :black;
          margin-top:0px;

          li{
            width:50%;
            backgruond-color:white;
            color :black;
            border :1px solid #c0c0c0;
            border-top:0px;

            &:nth-child(2n) {
              border-left:0px;
            }

            &:nth-child(1) {
              border-top:1px solid #c0c0c0;
            }
            &:nth-child(2) {
              border-top:1px solid #c0c0c0;
            }

          }
        }
    }


  }
}

.ranking-area{
  display: inline-block;
  ul{
    @extend .clearfix;
    li {
      text-align: center;
      padding: 3px 12px;
      box-shadow: 0px 2px 4px #aaa;
      margin: 3px 2px;
      border-radius: 3px;
      float: left;
      background-color: #d4cfc1;
    }
  }
}

#formbox,#loginframe{
  input[type="text"] {
    width: 100%;
  }

  input[type="password"] {
    width: 100%;
  }
  input[type="submit"]{
    @extend .button.pattern17.o ;
    width:300px;
    height:40px;
    line-height:24px;
    margin:22px auto;
  }

  &.activate{
    width: 70%;
    margin: 0 auto;
    @include mqSP {
      width: 100%;
      margin: 0 auto;
    };
  }

  &.activate_confirm{
    width: 50%;
    margin: 0 auto;
    @include mqSP {
      width: 100%;
      margin: 0 auto;
    };
  }
}

.search-top-area{
  .descript{
    h1{
      color: #f08228;
      font-size: 150%;
      margin-bottom: 12px;
      &.has-description{
        border-bottom:1px solid silver;
      }
    }
  }
}
