body{
   background-color:#F5F3EE;
  #wrapper{

    &.admin{
      background-color: white;
    }
  }
}

:root{
  @include font-color(5);
}
a{
  @include font-color(5);
}
a:hover{
  @include font-color(7);
}

p.toggle-lines {
    display: table-cell !important;
    padding-right: 15px;
}
.toggle-lines-sibling {
    position: relative;
    float: right;

    > div {
      position: absolute;
      top: -20px;
      right: 0px;
    }
}



#wrapper{
    @extend .clearfix;
    margin: 0 auto;
    position:relative;
    overflow: hidden;
    
    @include mqSP {
      width:100%;
      min-width:auto;
      max-width:auto;
    }

   &.w-border{
    max-width:1000px;
    box-shadow: 20px 0px 12px -8px rgba(0, 0, 0, 0.1),-12px 0px 12px -8px rgba(0, 0, 0, 0.1);
    background-color:#F5F3EE;
   }
}

.inner{
    @extend .clearfix;
    max-width:1000px;
    margin: 0 auto;
    position:relative;

    @include mqSP {
      margin: 0 2%;
      width:96%;
      min-width:auto;
      max-width:auto;
    }
}

.pc-inner{
    @extend .clearfix;
    max-width:1000px;
    margin: 0 auto;
    position:relative;

    @include mqSP {
      width:100%;
      min-width:auto;
      max-width:auto;
    }
}


.sp-inner{
    @include mqSP {
      margin: 0 2%;
      width:96%;
      min-width:auto;
      max-width:auto;
    }
}

.sp-outer{
    @include mqSP {
      width:100%;
      min-width:auto;
      max-width:auto;
      margin:0;
    }
}


.section-inner{
    @extend .clearfix;
    max-width:820px;
    margin: 0 auto;
    position:relative;

    @include mqSP {

      margin: 0 2%;
      width:96%;
      min-width:auto;
      max-width:auto;
    }
}

img.fb{
  width:80%;
   margin-top: 25%;
}



.ranking-overwrite{
  margin-top:22px;
  text-align:left;

  width: 100%;
  max-width: 100%;

  height:auto;
  padding:0;
  @extend .clearfix;
  margin: 22px 0 0 0;

  .ranking-overwrite_content{
    @extend .clearfix;
    color: #50463c;
    font-size: 20px;
    font-size: 2rem;
    line-height: 32px;
    line-height: 3.2rem;
    border: 1px solid silver;
    padding: 10px;
    margin-bottom: 25px;
    background: white;
  }

  h3{
    text-align: center;
  }
  .area_footer_navi{
    padding: 10px 12px;
    background: transparent;
    border-bottom: 1px solid #c0c0c0;
    padding-bottom: 8px;
    .title {
      display:inline-block;
      width:80px;
      text-align: center;
      @extend .clearfix;

      vertical-align:top;
      padding-top:10px;
      @include font-set(1,14,2);
    }
    .info{
      display:inline-block;
      width: -webkit-calc(100% - 100px);
      width: calc(100% - 100px);
      @include font-set(1,12,2);

      a:before{
        content: "・";
      }
    }
    &:last-child{
      border-bottom: 0px solid #c0c0c0;
    }
  }
}
