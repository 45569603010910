.breadcrumb{
    @extend .clearfix;
    padding:17px 0 12px 0;
    li{
		text-align: left;
	    @include fzauto(14);
        display:inline-block;
        padding: 0px 3px;

		&:after{
			content: ">";
		}
		&:last-child{
			&:after{
				content: "";
			}
		}
		.home{
			font-size:0px;
			i{
			    @include fzauto(22);
			}
		}
	}
}
