table { width: 100%; }
th, td  { padding: 7px; border: 1px solid #ddd; }
th  { background: #f4f4f4; }

table tr{
  background: white;
  &.odd{
    background: #f0f0f0;
  }
}
table th{
//  width: 30%;
  text-align: center;
  background: #008ae0;
  border-bottom: 1px solid #e0effa;
  color: #635353;
  @include  fzauto(14);
}

table td  {
  text-align: left;
  border: 1px solid white;
  color: #635353;
  @include  fzauto(14); 
}

table.has_two{
    th{
      width:50%;
    }
    td{
      width:50%;
    }
}
table.has_four{
    th{
      width:25%;
      width:250px;
    }
    td{
      width:25%;
      width:250px;
    }
}

table th,td{
  &.w-10 {width:10% !important;}
  &.w-20 {width:20% !important;}
  &.w-30 {width:30% !important;}
  &.w-40 {width:40% !important;}
  &.w-50 {width:50% !important;}
  &.w-60 {width:60% !important;}
  &.w-70 {width:70% !important;}
  &.w-80 {width:80% !important;}
  &.w-90 {width:90% !important;}
}


@media only screen and (max-width:480px){
    .ptn1 th,
    .ptn1 td{
      width: 100%;
      display: block;
      border-top: none;
    }
    .ptn1 tr:first-child th { border-top: 1px solid #ddd; }
}

.ptn2 th  { width: 13%; }
.ptn2 td  { text-align: center; }
.ptn2 td:first-child  { text-align: left; }
@media only screen and (max-width: 800px) {
  .ptn2 {
    display: block;
    width: 100%;
    margin: 0 -10px;
  }
  .ptn2 thead{
    display: block;
    float: left;
    overflow-x:scroll;
  }
  .ptn2 tbody{
    display: block;
    width: auto;
    overflow-x: auto;
    white-space: nowrap;
  }
  .ptn2 th{
    display: block;
    width:auto;
  }
  .ptn2 tbody tr{
    display: inline-block;
    margin: 0 -3px;
  }
  .ptn2 td{
    display: block;
  }
}


.ptn3 th, .ptn3 td  { width: 20%; text-align: left; }
@media only screen and (max-width: 480px) {
  .ptn3 { margin: 0 -10px; }
  .ptn3 tr {
    display:block;
    margin-bottom: 10px;
  }
  .ptn3 th {
    display:block;
    width: 100%;
  }
  .ptn3 td {
    display: list-item;
    width: 90%;
    margin-left: 10%;
    border:none;
  }
}
