.image.vertical{
  img{
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    top: 50%;
    width: auto;
    -webkit-transform: translate3d(-50%, -50%, 0);
       -moz-transform: translate3d(-50%, -50%, 0);
             -ms-transform: translate(-50%, -50%);
            transform: translate3d(-50%, -50%, 0);
  }
}

.qa-images{
  position: relative;
  .qa_electric{
    position: absolute;
    left:5%;
    top:10%;
  }
  .text{
    position: absolute;
    left: 20%;
    top: 38%;
    color: #74726C;
    font-weight: bold;
    @include mqSP {
      left: 28%;
      top: 16%;
      width: 80%;
      text-align: left
    ;
    };
  }
}
