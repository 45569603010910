.animate{
	&.pulse{
	    animation-duration: 2s;
	    animation-delay: 300ms;
	    animation-iteration-count: infinite;
	    animation-name: pulse;
	}


	&.hover-route{
	  -webkit-transition: all 0.6s ease;
	  transition: all 0.6s ease;

	  &:hover{
		  -webkit-transform: rotateY(180deg);
		  transform: rotateY(180deg);
	  }
	}
}
 
