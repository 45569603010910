.bx-wrapper{
	margin:0;
}

.bx-wrapper .bx-viewport {
 left:0px;
}

.bx-wrapper .bx-pager{
	padding-top:0px;
	bottom:0px;
}

.no-navi{
	.bx-pager.bx-default-pager{
		display:none;
	}
}