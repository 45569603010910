/*! 定義 */

/* font-family*/
$font-family-sans-serif: 'Lucida Grande', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, sans-serif;
$font-family-hiragaku: "Hiragino Kaku Gothic ProN","メイリオ", sans-serif;
$font-family-ms-gothic: "MS PGothic", "Osaka", Arial, sans-serif;
$font-family-u-gothic: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

$font-family-meirio: "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", sans-serif;
$font-family-hiragino:Hiragino Kaku Gothic Pro,ヒラギノ角ゴ Pro W3,Meiryo, メイリオ,Osaka, 'MS PGothic', arial, helvetica, sans-serif;

$font-family-from-facebook: helvetica, arial, 'hiragino kaku gothic pro', meiryo, 'ms pgothic', sans-serif;
$font-family-from-rakuten: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif;
$font-family-from-cookpad: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;

$font-family-ref-japan-base: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;



/*! 変数定義 */

$main-bg-color :  white;
$main-font-color : black;

$header-bg-color : white;
$header-font-color :black;

$footer-bg-color : black;
$footer-font-color :white;


$font-family:$font-family-hiragino;

//Content の Max width 0の場合は 未指定
$widthMaxcontent : 1000px;
$widthcontent: 100%;

//PC大
$widthXL: 1280px;
//PC小
$widthLG: 1280px;
//タブレット
$widthMD: 768px;
//スマートフォン
$widthSM: 768px;
