.footer-pre{
    padding-top: 30px;
    @extend .clearfix;
    .inner{
        min-height: 300px;
        margin-bottom: 55px;
    }
    .about{
        float:left;
        @include fzauto(17);
        width:50%;
        p{
            margin: auto;
            width: 275px;
        }
        p.info{
                padding: 20px 0;
        }
        .line{
            background: #00c200;
            color: white;
            width: 156px;
            border-radius: 6px;
            height: 40px;
            line-height: 40px;
            img{
                width: 30px;
            }
        }

        @include mqSP {
          margin: 0 2%;
          width:96%;
          min-width:auto;
          max-width:auto;
          clear:both;
          float:none;
          text-align: center;
        }

    }

    .facebook{
        width:50%;
        float:right;

        @include mqSP {
          margin: 0 2%;
          width:96%;
          min-width:auto;
          max-width:auto;
          clear:both;
          float:none;
          padding-top:22px;
        }
    }
}

/* footer */
#footer {
    @extend .clearfix;
    @include fzauto(14);
    background-color:#E4E1DC;
    padding-top: 24px;

    nav.footer-nav{
        position: relative;
	    margin: 0 auto;
	    text-align: center;
    }
    .footer-add{
        @extend .clearfix;
        img{
            float:left;
        }
        a{
            float:right;
            @extend .button.pattern17 ;
            background-color: #575349 !important;
            height: 38px  !important;
            line-height: 41px  !important;
            box-shadow: 0 3px 0 black !important;

            &:hover {
                top: -4px;
                box-shadow: 0 7px 0 black !important;
            }

        }
    }

    .footer-nav{
        .b-navi{
            @extend ul.separater.line;
            @extend .clearfix;
            li{
               float: left;
                margin: 12px 0px 0px 2px;
                padding: 0 9px;

                a{
                    color:black;
                    &:hover{
                        color :#B2ADA0 ;
                    }
                }
            }



            @include mqSP {
                padding-top: 24px;

                li{
                    float: left;
                    margin: 0px 0px 0px 2px;
                    padding: 0 9px;
                    width: 100%;
                    border: 0;
                    border-top: 1px solid #c0c0c0;
                    padding: 11px;


                    a{
                        color:black;
                        &:hover{
                            color :#B2ADA0 ;
                        }
                    }

                    &:last-child{
                        border :0;
                        border-top :1px solid #c0c0c0;
                        border-bottom :1px solid #c0c0c0;
                    }
                }

            }



        }
        .n-navi{
            @extend ul.separater.disc;
            @extend .clearfix;

            @include mqSP {
                padding-top:22px;
            }
            li{
                float: left;
                margin: 12px 0px 40px 22px;
                padding: 0 0px;
                a{
                    color:black;
                    &:hover{
                        color :#B2ADA0 ;
                    }
                }

                @include mqSP {
                    float: left;
                    margin: 0 0 0 7%;
                    width: 42%;
                    text-align: left;
                    padding: 9px;
                }

            }
        }
    }
}

#footer-obi{
    position: relative;
    background-color:#5C584F;

    .obi{
        width:100%;
        text-align: center;
        padding: 10px;
    }

    .copyright {
        color:white;
        @include fzauto(11);
    }
}
