 a.circle{
    box-shadow: 0 0 0 4px white;
    -webkit-transition: background 0.2s, color 0.2s;
    -moz-transition: background 0.2s, color 0.2s;
    transition: background 0.2s, color 0.2s;
    display: inline-block;
    font-size: 40px;
    cursor: pointer;
    margin: 15px 30px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    position: relative;
    z-index: 1;
    color: #fff;
    background-color: red;
    padding-top: 15px;
    &.clear{
	    background-color: blue;
    }
 }


a.list{
    &:after {
        border-right: 2px solid #712121;
        border-top: 2px solid #712121;
        content: "";
        display: block;
        height: 6px;
        margin: -4px 0 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: rotate(45deg);
        width: 6px;
    }
}