span.f-u{
	font-size:120%;
}
span.f-d{
	font-size:80%;
}

span.w-shadow{
	color:white;
	text-shadow:0 0 4px #fff524;
}

/* プレイスホルダー内の文字色の変更 */
:placeholder-shown {
	color: #ccc; }

/* Google Chrome, Safari, Opera 15+, Android, iOS */
::-webkit-input-placeholder {
	color: #ccc; }

/* Firefox 18- */
:-moz-placeholder {
	color: #ccc; opacity: 1; }

/* Firefox 19+ */
::-moz-placeholder {
	color: #ccc; opacity: 1; }

/* IE 10+ */
:-ms-input-placeholder {
	color: #ccc; }
