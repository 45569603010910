.feature.page{

  /* PC */
  #topfirst{
    width:750px;
  }
  #lp #maintxt p{
    font-size:14px;
    line-height: 22px;
  }
  #lp #maintxt #rightblock{
  width: 410px;
    margin-left: 15px;
    float: right;
    text-align: left;
  }
  #lp b{
    margin:10px 0;
  }
  #lp{
    font-size:16px;
    line-height: 26px;
    width: 680px;
    margin: 0 auto;
  }
  .lph2{
    font-size: 24px!important;
    color: #000!important;
    font-weight: bold!important;
    letter-spacing: 1px;
    line-height: 40px;
    padding: 28px 12px 20px!important;
  }
  #lp h3{
    color: #4c483f;
    padding: 10px 15px 10px 20px;
    border-left: 10px solid #4c483f;
    background: #e3e1db;
    font-size:18px;
    border-top:0px solid #fff;
    border-bottom:0px solid #fff;
    margin:0 0 20px;
  }
  #lp .b_shops{
    margin: 50px 0;
    text-align: left;
  }
  #lp table{
    font-size: 14px;
    margin: 20px 0;
  }
  #lp h4{
  border-left: 0px!important;
    border-bottom: 1px solid #4c483f;
    padding: 10px 0px 5px 20px;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .tk_img{
  width: 680px;
  }
  #lp table th{
  background: #4c483f;
  font-size: 12px;
  color: #FFF;
  }
  #lp table td{
  background: #fff;
  font-size: 12px;
  }
  #lp .quote{
    background-color: #e6e6e6;
    background-image: url(../../images/common/quote.png);
    background-position: 5px 5px;
    background-repeat: no-repeat;
    background-size: 25px auto;
    padding: 30px 20px 20px;
  }
  #lp .ichiranhe{
    background-color:#f1862b;
    width: 300px;
    padding:15px;
    margin:20px auto 0;
    text-align: center;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
  }
  #lp .ichiranhe u{
    color:#fff;
    font-size:14px;
  }
  #lp .ichiranhe u a:hover{
    text-decoration: none!important;
  }



  @include mqSP {

    #lp {
      padding: 0 10px;
      width: 100%;
    }
    #lp #maintxt p {
      font-size: 14px;
      line-height: 22px;
    }
    #lp b {
      margin: 10px 0;
    }
    #lp {
      font-size: 16px;
      line-height: 26px;
    }
    #lp h2 {
      color: #4c483f!important;
      font-weight: bold;
      font-size: 20px!important;
      letter-spacing: 1px;
      margin-bottom: 23px;
      overflow: hidden;
      /*padding: 0.8em;*/
    }
    #lp h3 {
      color: #4c483f;
      font-size: 18px;
      text-align: left;
      padding: 8px 10px 8px 12px;
      border-left: 10px solid #4c483f;
      background: #e3e1db;
    }
    #rightblock{
      text-align: left;
      margin-top:20px;
      width: 100%!important;
      margin-left: 0;
    }
    .b_shops {
      margin: 50px 0;
      font-size:14px;
      text-align: left;
    }
    #lp h4 {
    border-left: 0px;
      border-bottom: 1px solid #4c483f;
      padding: 10px 0px 5px 15px;
      font-size: 16px;
      color: #4c483f;
      text-align: left;
      margin-bottom: 20px;
    }
    .tk_img{
     width: 100%; 
    }
  #lp table{
    margin:20px 0;
  }
    #lp th {
      width: 12%;
      text-align: center;
      background-color: #4c483f!important;
      border-bottom: 1px solid #fff!important;
      color: #fff!important;
      padding: 6px;
    }
    #lp td {
      padding: 6px;
    }
    #lp .quote {
      background-color: #e6e6e6;
      background-image: url(../../images/common/quote.png);
      background-position: 5px 5px;
      background-repeat: no-repeat;
      background-size: 25px auto;
      padding: 30px 20px 20px;
    }
    .ichiranhe {
      background-color: #f1862b;
      width: 80%;
      padding: 15px;
      margin: 20px auto 0;
      text-align: center;
      border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
    }
    .ichiranhe u {
      color: #fff;
      font-size: 14px;
    }
    .ichiranhe u a:hover {
      text-decoration: none !important;
    }
  }

  .ggmap {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }

  .ggmap iframe,
  .ggmap object,
  .ggmap embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}