.p-area{
	
	@extend .clearfix;
	>div{
		float:left;
	}
}

ul.pager{
	@extend .clearfix;

	text-align: center;
	font-size: 0rem;
	margin-top: 20px;

	li{
		position: relative;
		display: inline-block;
		padding-bottom: 25px;
		a{
			display: inline-block;
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			-o-box-sizing: border-box;
			-ms-box-sizing: border-box;
			box-sizing: border-box;
			width: 40px;
			font-size: 1.4rem;
			line-height: 28px;
			text-decoration: none;
			color: #333333;
			margin: 0 4px;
		}

		&.active{
			a{
				color:#c0c0c0;
			    border-bottom: 1px solid #c0c0c0;
			}
		}

		&.prev , &.next{
			a{
				width: 30px;
				height: 30px;
			    font-size: 2.2rem;
			    line-height: 28px;
			    text-decoration: none;
			    color: #675c5c;
			    margin: 0 4px;
			    border-radius: 50%;
			    border: 1px solid #675c5c;

			}
		}
	}	
}

