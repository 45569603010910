.tabs{
	width:100%;
	border-top: 1px solid silver;
	border-bottom: 1px solid silver;
	@extend .clearfix;
	.tab,.tab-n{
		position:relative;
	    display: inline-block;
	    -moz-box-sizing: border-box;
	    /* -webkit-box-sizing: border-box; */
	    -o-box-sizing: border-box;
	    -ms-box-sizing: border-box;
	    /* box-sizing: border-box; */

	    float:left;
	    border: solid 1px #c0c0c0;
	    width: 33%;
	    margin: 0 0px 0px;
	    font-size: 1.4rem;
	    line-height: 52px;
	    color: #333333;
	    text-decoration: none;
	    text-align: center;
    	&:before{
		   	content: "";
	    	display: table;
		}
		&:after{
		   	content: "";
	    	display: table;
		}

		&.active{
			background-color: #f1862b;
			@include font-color(9);
			&:after{
			   	content: "";
		    	display: table;
					background-color: #f1862b;
			}
		}



		.link{
		    border-top-color: black;
		    border-bottom-color: black;
		}

		.notice_batch{
			position: absolute;
			background-color: red;
			right: 5%;
			height: 8px;
			width: 8px;
			margin-top: 4%;
		}

		&.current{
		    border-color: black;
		    background-color:transparent;
		    border-bottom: 0px;

			.link{
				fon-weight:bold;
				 @include font-color(2);
			}
		}
		>a{
			width:100%;
		    display: inline-block;
		}
	}
}
.tabs.navi{
	.tab-n{
		float:left;
		border:0px;
		position: relative;
		.caption{
			z-index:10;
			 background:transparent;
			 position: relative;
			 padding-left: 20px;
		}
		.after{
			content: " ";
			 position: absolute;
			 right: -14px;
			 top: 0px;
			 width: 0;
			 height: 0;
			 border-width: 26px 13px;
			 border-style: solid;
			 border-color: red;
			 border-left-color: transparent;
			 content: '';
			 width: 36px;
			 height: 36px;
			 border: 0px;
			 border-top: solid 1px #c0c0c0;
			 border-right: solid 1px #c0c0c0;
			 -ms-transform: rotate(45deg);
			 -webkit-transform: rotate(45deg);
			 transform: rotate(45deg);
			 position: absolute;
			 top: 50%;
			 right: -18px;
			 margin-top: -19px;
			 z-index: 1;
			 background: #f5f3ee;

		}
	}
	.tab{
	   float:left;
	   border:0px;
 	 	&:after{
		    content: " ";
		    position: absolute;
		    right: -14px;
		    top: 0px;
		    width: 0;
		    height: 0;
		    border-width: 26px 13px;
		    border-style: solid;
		    border-color: red;
		    border-left-color: transparent;
		    content: '';
		    width: 36px;
		    height: 36px;
		    border: 0px;
		    border-top: solid 1px #c0c0c0;
		    border-right: solid 1px #c0c0c0;
		    -ms-transform: rotate(45deg);
		    -webkit-transform: rotate(45deg);
		    transform: rotate(45deg);
		    position: absolute;
		    top: 50%;
		    right: -18px;
		    margin-top: -19px;
				z-index: 1;
				background: #f5f3ee;
	   }
	}


	.tab.active:after,.tab-n.active .after{
			background:#f1862b;
	}
}
.tabs.has_2{
	.tab{
        @include mqSP {
	    	width: 50%;
	    }
	}
}

.tabs.ranking{

	border-top: 0px;
	border-bottom: 1px solid silver;

	.tab {
	    background-color:#D4CFC1;
		border-color:silver;

		&.current{
	    	background-color:transparent;
		    font-weight: bold;
			font-size: medium;
		}
	}
}


.tabs.menus{
	.tab {
		border-top:0px;
		border-left:0px;
		border-bottom:0px;
		border-right:1px solid silver;

		width:20%;

        @include mqSP {
        	font-size:1.0rem;
        }
        &:last-child{
	        border-right:0px;
        }
	}
}
