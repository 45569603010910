.member{
	&.question{
		margin: 0 auto;
		position: relative;
		text-align: center;

		&.review {

			background: white;
			margin-top: 30px;
			text-align: center;
			display: inline-block;
			width: 100%;
			box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;

			.page-title {
				text-align: left;
				padding: 10px;
				border-bottom: 1px solid #000000;
			}

			.detail-text {
				text-align: left;
				padding: 3%;
			}

			.contents-information {
				margin: 5%;
				border: 1px solid #000000;

				h4 {
					margin-top: 5%;
					margin-bottom: 1%;
				}

				&.confirm{
					text-align: left;
					h5 {
						margin-top: 1%;
						margin-left: 5%;
					}

					ul.star{
						li {
							color :#FFC001;
							font-size:140%;
							text-align:right;

							display:inline-block;
							margin:0;

							&:first-child{
								margin-left:0px;
							}

							&:last-child{
								margin-right:0px;
							}
						}
						.number{
							font-size:100%;
						}
					}

				}
			}

			.contents{
				width: 90%;
				padding-bottom: 3%;
				margin-bottom: 3%;
				input[type=radio]{
					margin-left:1%;
				}

			}
		}
	}

	.mypage{
		background-color: transparent;
	}

	#formbox{
		background-color: transparent;
	}

	.contents{
		margin: 0 auto;
		width: 60%;
		padding: 1% 5%;

		@include mqSP {
			width: 90%;
		}

		.title{
			margin-bottom: 2%;
		}

		&.border{
			border-bottom: 1px solid #c0c0c0;
		}

		.thumbnail{

		}

		textarea{
			width: 100%;
		}
		select{
			width: 100%;
		}

	}

	.contents-wide{
		background-color: #DFDCD6;
	}

	.point{
		//text-decoration:underline;
		margin: 2% auto;
	}

	.img_example{
		margin: 10px auto;
	}

	hr{
		display: none;
		border: 1px solid #c0c0c0;
		width: 70%;
		margin: 3% auto;
	}

	textarea{
		font-size: 1.0em;
	}

	.member-title{
		@include font-color(2);
	}
}

.left-message{
	display: inline-block;
	text-align: left
}

.border-bottom{
	border-bottom: 1px solid #c0c0c0;
}

.services{
	background-color: #ebe9e5;
	overflow: hidden;
	margin-top: 50px;
	.services-contents{
		max-width: 1000px;
		margin: 0 auto;
		padding-bottom: 40px;
		overflow: hidden;
		p.services-title{
			@include font-set(1,16,2);
			padding-top: 40px;
			padding-bottom: 40px;
			font-weight: bold;
		}
		.comment{
			width: 50%;
			float: left;
			@include mqSP{
				width: 100%;
				float: none;
			};
			p.comment-title{
				@include font-set(2,14,2);
				font-weight: bold;
				padding-bottom: 30px;
			}
			p.comment-sentence{
				width: 70%;
				margin: 0 auto;
				padding-top: 30px;
			}
		}
		.consultation{
			width: 50%;
			float: left;
			@include mqSP{
				width: 100%;
				float: none;
			};
			p.consultation-title{
				@include font-set(2,14,2);
				font-weight: bold;
				padding-bottom: 30px;
				@include mqSP{
					padding-top:40px;
				};
			}
			p.consultation-sentence{
				width: 70%;
				margin: 0 auto;
				padding-top: 30px;
			}
		}
	}
}
