.visual{
	position:relative;
	text-align:center;

	height:400px;
	overflow:hidden;
	&.shops{
		height:342px;
		height:auto;

		.bx-pager{
//			display:none;
          margin-bottom: 6%;
			z-index: 100;
		}
		.shopslieders > li >img {
			width: 100%;
		}
	}
	&.main{
		height:450px;
		height:auto;
	}

	ul.top-visual{
		height:450px;
		.im{
			height:450px;
			width:auto;
			background-size: cover;
    		background-position: center;
		}

		@include mqSP {
			height:350px;
			.im {
				height: 350px;
			}
		}


	}
	.visual-wrppaer{
		position: absolute;
	    background-color: rgba(0, 0, 0, 0.31);
	    top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
	    margin: auto;
	    .inner{
	        height: 100%;
	        padding-top: 150px;
   			.contents{
	   			text-align: center;

	   			.title{
	   				font-size: 33px;
					color: white;
	   			}
   			}
	    }

	    ul.visual-footer{
	    	@extend .clearfix;
	    	position: absolute;
		    bottom: 38px;
		    right: 0;
		    color: white;

		    font-size: 16px;
		    font-weight:bold;

           @include mqSP {
	          	left:0px;
            }

		    li{
		    	float:left;
		    	margin-left:60px;
		    	&:first-child{
		    		margin-left:0;
		    	}
		    }

		    &.menu{
			    bottom: 0px;
			    right: 0;
			    color: white;
			    font-size: 19px;
			    background-color: rgba(0, 0, 0, 0.52);
			    width: 100%;
			    padding: 10px 0;
			    text-align: center;
			    color: white;
			    margin-left: 111px;


		          @include mqSP {
		          	display:none;
		          }

			    li{
			        width: 30%;
				    text-align: center;
				    font-size: 1.3rem;
				    color: white;
				    margin-left:0px;
				    a{
				    color: white;
				    
				    }
			    }
		    }
	    }
	}
}

.shop .visual .visual-wrppaer{
	background-color: transparent;
	top: inherit;
	.inner {
		height: inherit;
		padding-top: 0px;
	}
	.visual-footer.menu{
		font-weight:100;
		li:nth-of-type(2) {
			border-right:1px solid white;	
			border-left:1px solid white;
		}
	}
}
