#messageArea{
  display: none;
    position: fixed;
    z-index: 900;
    top: 0px;
    left: 0;
    width: 100%;
    height: 32px;
    color: #000;
    background-color: #ffa500;
    text-align: center;
    #message{
      color: white;
      font-size: 14px;
      padding-top: 7px;
    }
}
.navis{
  margin:22px;
  a{
    img{
      width:44%;
      margin: 0 2%;
      display :inline-block;
    }
  }
}

#top_count_sp{
  background-color:white;
  li {
    display:inline-block;
    color: black;
    font-size: 15px;
    font-weight: bold;
    padding: 18px;

  }
}
nav.global-nav{
  position: absolute;
  top: 88px;
  width: 100%;
  z-index: 10000;
  background: rgba(255, 253, 253, 0.95);
  height: 100%;

  .nav{
    background-color:white;
  }

  li{
    padding:18px;
    border : 1px solid #c0c0c0;
  }
}


#global-nav{
  @extend .clearfix;
  ul.menus.sp-only{
    height:65px;
  }
  
  position:absolute;
  top:20px;
  left:0;
  z-index: 11;
  width:100%;
  .logo{
    float:left;
    width:133px; 
    @include mqSP {
        margin-top:6px;
     }
  }
  .menus {
    float:right;
    font-size: 120%;

    li{
      display:inline-block;
      a{
        float:right;
        border : 1px solid white ;
        border-radius: 5px;
        padding: 3px 12px;
        margin-left:12px;

        border-radius: 5px;

        @include mqSP {
          border : 0px;
          padding: 0;
          margin-left:4px;
          text-align: center;
          font-size: 30px;
          color: white;
        }

        p{
          color: white;

          @include mqSP {
            font-size: 10px;
            font-size:1.0rem;
          }

        }
        &:hover{
          border : 1px solid #c0c0c0 ;

          @include mqSP {
            border : 0px;
          }

          p{
            color: #c0c0c0;
            @include mqSP {
               color: white;
            }


          }
        }
      }
      a.link{
        border : 0px solid white ;
        border-right : 1px solid white ;
        border-radius: 0px;
        margin-left: 0px;

        &:hover{
          border : 0px solid white ;
          border-right : 1px solid white ;
          @include mqSP {
            border : 0px;
          }
        }

        &.last{
          border-right : 0px solid white ;

        }
      }

    }
  }

  &.page{
    position:relative;
    margin-bottom: 20px;
    @include mqSP {
      margin-bottom: 0px;
    }

    span{
      color:black;
    }
    .menus {
      li{
        a{
          border: 1px solid #7f7a7a;
          border-radius: 5px;

          @include mqSP {
            border : 0px;
            color: black;
          }

          p{
            color: black;
          }
          &:hover{
            border : 1px solid #c0c0c0 ;

            @include mqSP {
              border : 0px;
            }

            p{
              color: #c0c0c0;
              @include mqSP {
                 color: black;
              }
            }
          }
        }
        a.link{
          border : 0px solid black ;
          border-right : 1px solid black ;
          border-radius: 0px;
          margin-left: 0px;

          &:hover{
            border : 0px solid black ;
            border-right : 1px solid black ;
            @include mqSP {
              border : 0px;
            }
          }

          &.last{
           border-right : 0px solid black ;

          }
        }

      }
    }

  }
}


#header-member{
  @extend .clearfix;
  li{
    width:20%;
    float: left;
    text-align: center;
    border: 1px solid #c0c0c0;
    font-size: 14px;

  }
}

#top-head .page .menu-trigger span {
  background-color:black;
}


/* header */
#top-head {
	@extend .clearfix;
	width: 100%;
	transition: top 0.65s ease-in;
	-webkit-transition: top 0.65s ease-in;
	-moz-transition: top 0.65s ease-in;
	background-color:white;
	border-bottom: 1px solid #d0caca;

	.menu-trigger{
		display: inline-block;
		transition: all .4s;
		box-sizing: border-box;
		position: relative;
		width: 50px;
		height: 44px;


      p{
      position: absolute;
      bottom: 0;
      left: 4px;
      }
      

		span{
			display: inline-block;
			transition: all .4s;
			box-sizing: border-box;
			position: absolute;
			left: 0;
			width: 100%;
			height: 4px;
			background-color: #fff;
			border-radius: 4px;

			&:nth-of-type(1) {
				top: -4px;
        height:2px;
        width: 35px;
        margin-left: 5px;

			}
			&:nth-of-type(2) {
				top: 7px;
        height:2px;
        width: 35px;
        margin-left: 5px;

			}
			&:nth-of-type(3) {
        bottom: 25px;
            height:2px;
        width: 35px;
        margin-left: 5px;

			}
		}

		//links http://www.nxworld.net/tips/12-css-hamburger-menu-active-effect.html
		&.pattern1{
			&.activate{
				span{
					&:nth-of-type(1) {
						-webkit-transform: translateY(20px) rotate(-45deg);
						transform: translateY(20px) rotate(-45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						-webkit-transform: translateY(-20px) rotate(45deg);
						transform: translateY(-20px) rotate(45deg);
					}
				}
			}
		}

		&.pattern2{
			&.activate{
				span{
					&:nth-of-type(1) {
						-webkit-transform: translateY(20px) rotate(-315deg);
						transform: translateY(20px) rotate(-315deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						-webkit-transform: translateY(-20px) rotate(315deg);
						transform: translateY(-20px) rotate(315deg);
					}
				}
			}
		}

    &.pattern3 {
      -webkit-transform: rotate(360deg);
    	transform: rotate(360deg);
      &.active{
        span{
          &:nth-of-type(1) {
          	top:-13px;
            -webkit-transform: translateY(20px) rotate(-45deg);
          	transform: translateY(20px) rotate(-45deg);

          }
          &:nth-of-type(2) {
          	-webkit-transform: translateY(0) rotate(45deg);
          	transform: translateY(0) rotate(45deg);
          }
          &:nth-of-type(3) {
          	opacity: 0;
          }
        }
      }
    }

    &.pattern4 {
      span{
        &:nth-of-type(3) {
        	transition: none;
        }
      }
      &.active {
        -webkit-transform: rotateX(720deg);
      	transform: rotateX(720deg);
        span{
          &:nth-of-type(1) {
          	-webkit-transform: translateY(20px) rotate(-45deg);
          	transform: translateY(20px) rotate(-45deg);
          }
          &:nth-of-type(2) {
          	-webkit-transform: translateY(0) rotate(45deg);
          	transform: translateY(0) rotate(45deg);
          }
          &:nth-of-type(3) {
          	transition: none;
            opacity: 0;
          }
        }
      }
    }

    &.pattern8 {
      span{
        &:nth-of-type(1){
          width: 20px;
        }
      }
      &.active{
        span{
          &:nth-of-type(1) {
          	-webkit-transform: translate(-1px,13px) rotate(-45deg);
          	transform: translate(-1px,13px) rotate(-45deg);
          }
          &:nth-of-type(3) {
          	width: 20px;
            -webkit-transform: translate(-1px,-13px) rotate(45deg);
          	transform: translate(-1px,-13px) rotate(45deg);
          }
        }
      }
    }

    &.pattern9 {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
      &.active{
        span{
          &:nth-of-type(1){
            width: 20px;
            -webkit-transform: translate(-1px,13px) rotate(-45deg);
            transform: translate(-1px,13px) rotate(-45deg);
          }
          &:nth-of-type(3){
            width: 20px;
            -webkit-transform: translate(-1px,-13px) rotate(45deg);
            transform: translate(-1px,-13px) rotate(45deg);
          }
        }
      }
    }

    &.pattern10 {
      &.active {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }

  &.top{
      position:absolute;
      color:white;

      
  }

}
