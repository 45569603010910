.thumbnail{
    &.circle{
        img{
            width:100%;
            border-radius: 50%;
            -moz-border-image: 50%;
            -webkit-border-image: 50%;
        }
    }

    &.circle-border{
        @extend  .circle;
        border: 5px solid WHITE;
        border-radius: 50%;
        -moz-border-image: 50%;
        -webkit-border-image: 50%;
        box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px#bdb0b0;
    }

  
}
