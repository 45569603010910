.ac-cap-p{
  position:relative;
  padding-left: 25px;
  &::before
  {
    content: "▶︎";
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
    font-size: 1.1rem;
    color:black;
    left:2px;
  }

}


//http://www.webdlab.com/labs/accordion-3/

.accordion {
  min-width: 300px;
  margin: 0 auto;
  padding: 10px;


  @include mqSP {
    padding:0;
    text-align: left;

  }
  &.ac-content{
    margin: 0 10px;
  }

  input{
   display: none;
  }
  input:checked + .ac-cont {
    height: auto;
    padding: 10px;
  }

  label {
    display: block;
    cursor: pointer;
    padding: 10px;
    background: transparent;
    position: relative;

    @include mqSP {
        border-bottom: 1px solid #dcd1d1;
        background: #ffffff;
    }
    &:hover {
      background: #ccc;
    }

  }

  .ac-cont {
      transition: 0.2s;
      height: 0;
      overflow: hidden;
      background: #ddd;
      padding: 0 10px;

      background: transparent;

      @include mqSP {
        background:white;
      }

      p{
        padding: 13px;
        width:100%;
        display: inline-block;

        a{
          color: #74726C;
          width: 98%;
          display: inline-block;
          border-bottom: 1px solid silver;
          padding: 2px;
        }
        &:before{
        //  content: ">";
        //  margin-right: 8px;
        //  font-weight: bold;
        }

      }
  }
}


.accordion .ac-cont p {
    padding: 2px 10px 10px;
    width: 100%;
    display: inline-block;
}


.search-top-area{

  .accordion{
    padding: 0;
    border: 1px solid silver;
  }
}


.a-m-a{
    padding: 0 !important;
  
  .accordion{
    padding:0;
    label{
      padding: 12px 16px;
    }
    .ac-cont{
      border-top:1px solid silver;
      padding:0 !important;
    }
    .dl-s{
      padding-left:18px;
    }

  }

}

.search-top-area{
  .accordion {
    label:after {
      content: "▼";
      color: #E19546;
      right: 8px;
      top: 12px;
      position: absolute;
    }
  }
}
