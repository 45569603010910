ul,ol{
	&.separater{
		&.none li,oi {list-style-type: none;}
		&.disc li,oi {list-style-type: disc;}
		&.circle li,oi {list-style-type: circle;}
		&.square li,oi {list-style-type: square;}
		&.line li,oi {
			border-left:1px solid #4F4B40;
			&:last-child{border-right:1px solid #4F4B40;}
		}
		&.line-l li,oi {
			border-left:1px solid #4F4B40;
			&:first-child{border-left:1px solid #4F4B40;}
			&:last-child{border-left:1px solid #4F4B40;}
		}

		&.line-v li,oi {
			width:100%;
			border-top:1px solid #4F4B40;
			&:first-child{border-top:1px solid #4F4B40;} 
			&:last-child{border-bottom:1px solid #4F4B40;}
		}

	}

	&.fl-list{
		@extend .clearfix;
		li,oi {
			float:left;
		}
	}


}
