.question{
	text-align:left;
}


#question_list{
	.items.question{
	   &:last-child{
		   border-bottom: 0px;
	   }
	}
}



.items.question{
   @extend .clearfix;
   margin-top: 35px;
   margin-bottom: 3px;
	@include mqSP{
		margin-top: 15px;
	}

   min-width: 100%;
   max-width: 100%;
   width: 100%;
   text-align: left;
   padding: 14px;

	&:last-child{
		border-bottom: 0px solid #c0c0c0;
	}

	.title{
		background-color:transparent;
		width: 100%;
		border-bottom: 1px solid #c0c0c0;
		padding-bottom: 1px;
		margin-bottom: 13px;
		h1{
			float:right;
			padding-left: 10px;
			width : calc(100% - 30px);
			@include font-set(1,16,2);
			padding-top:6px;
		}
		.advice{
		    width: 120px;
		    position: absolute;
		    right: 0;
		    background: white;
		    font-size: 1.0rem;
			text-align: center;

			@include mqSP{
				float:right;
				position:relative;
				margin: 5px auto;
			}

		}
	}
	.contents{
		background-color:transparent;
	}

	.info{
		width: 100%;
		@extend .clearfix;
		padding-bottom:12px;
		border-bottom: 1px solid #c0c0c0;

		.category {
			float:left;
		}

		.advice {
			float:right;
		}

		ul.category{
		    color: #74726C;
		    font-size: 10px;
		    font-size: 1rem;
		    line-height: 16px;
		    line-height: 1.6rem;

		    li{
			    display: inline-block;
			    padding: 3px 12px;
			    margin: 3px 2px;
			    border-radius: 3px;
			    background-color: #e3e1db;
				a:hover{
					@include font-color(2);
				}
			}
		}

	}

	.human{
		border-top :1px solid silver;
			@extend .clearfix;
//			@include font-set(1,14,2);
			position: relative;
			width:100%;
		    margin-top: 20px;
		    padding-top: 13px;

			.thumbnail{
				@extend .thumbnail.circle;
				width: 70px;
				float:left;
			}
			.name {
				padding-top: 24px;
				margin-bottom: 15px;
				display: inline-block;
				text-align: left;
				padding-left: 10px;
				@include font-set(1,16,1);

				span{
					font-weight:bold;
				}
			}
			.date{
				padding-top: 24px;
				margin-bottom: 15px;
				float:right;
			}

		}

}

.items.answer{
	@extend .clearfix;
	margin-top: 35px;
	margin-bottom: 3px;

	min-width: 100%;
	max-width: 100%;
	width: 100%;
	text-align: left;
	padding: 14px;

	.title{
		background-color:transparent;
		width: 100%;
		border-bottom: 1px solid #c0c0c0;
		padding-bottom: 1px;
		margin-bottom: 13px;
		h3{
			float:right;
			padding-left: 10px;
			width : calc(100% - 30px);
			@include font-set(1,14,2);
			padding-top:6px;
		}
		.advice{
			width: 120px;
			position: absolute;
			right: 0;
			background: white;
			font-size: 1.0rem;
			text-align: center;

		}
	}
	.contents{
		background-color:transparent;
	}
}

.items.teacher.answer-detail{
	@extend .clearfix;
	width: 100%;
	text-align: left;
	background-color: white;
	margin: 0 5px;
	margin-bottom: 45px;
	box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
	padding-bottom: 19px;

	.humans{
		position: relative;
		@include font-set(5,14,2);
		width: 50%;
		@include mqSP {
			float: none;
	    height: 90px;
	    width: 100%;
		}
		.thumbnail{
			@extend .thumbnail.circle;
			width: 85px;
			margin-right: 20px;
			float:left;
			@include mqSP{
				width: 85px;
			}
		}
	}

	.buttons{
		width:220px;
		position: absolute;
		right: 20px;
		top: 45px;
		@include mqSP{
			width: 100%;
			position: relative;
			right:0;
			top:10px;
			text-align: center;
			margin-bottom: 20px;
		}
	}
	.buttons-t{
		display:block;
		text-align: center;
		padding-top:12px;
		.button.clover{
			@extend .button.pattern17.g ;
			 	 text-align:center;
			 	 position : relative;
				  img {
				      width: 35px;
					    padding-left: 4px;
					    padding-right: 7px;
					    top: 9px;
					    left: 50px;
				  }
				  background: #7fbe25;
				  color:white;
				}
	}

	.infos{
		padding-left: 105px;
		padding-bottom: 20px;

		text-align: left;

		@include mqSP{
			padding-left: 0;
	    padding-bottom: 0;
	    float: left;
	    width: 145px;
		}
		.name{
			@include font-set(1,16,2);
			font-weight: bold;
			margin-bottom: 10px;
			@include mqSP {
				margin-bottom:0;
				float:left;
			}
		}

		.address{
  		@include font-set(5,12,1);
		}

		.advice{
			float:left;
			padding-right: 20px;
			@include font-set(5,12,1);
			@include mqSP {
				float:left;
				padding-right:0;
			};
		}
		.thanks{
			@include font-set(5,12,1);
			@include mqSP {
				float: left;
			}
			img.clover {
				width: 12px;
    		padding-bottom: 2px;
			}
		}
	}
}


.detail.question{
	.items.question{
		.title { border : 0px}
	}
}
