button.clover{
  img {
    width: 30px;
  }
  background: #00c200;
  color:white;
}

.button.facebook{
    img{
        width: 30px;
    }
    background: #00c200;
    color:white;
}

.button {

  display: inline-block;
  width: 200px;
  height: 54px;
  text-align: center;
  text-decoration: none;
  line-height: 54px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor:pointer;

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    display: block;
    content: '';
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
  }

  &.thanks{
    @extend .clearfix;
    text-align: center;
    background:#7fbe25;
    box-shadow: none;
  }

  &.detail{
    margin-top: 50px;
    @include mqSP {
      margin-top:10px;
      width: 90%;
    }
  }


  //links http://www.nxworld.net/tips/css-only-button-design-and-hover-effects.html
  &.pattern1{
    background-color: #333;
    color: #fff;
    &:hover{
      background-color: #59b1eb;
    }
  }

  &.pattern2{
    background-color: #333;
    border: 2px solid #333;
    color: #fff;
    line-height: 50px;
    &:hover{
      background-color: #fff;
      border-color: #59b1eb;
      color: #59b1eb;
    }
  }

  &.pattern3{
    position: relative;
    z-index: 2;
    background-color: #333;
    border: 2px solid #333;
    color: #fff;
    line-height: 50px;
    &:hover{
      background-color: #fff;
      border-color: #59b1eb;
      color: #59b1eb;
      &::before,
      &::after {
        width: 0;
        background-color: #59b1eb;
      }
    }
    &::before,
    &::after {
      top: 0;
      width: 50%;
      height: 100%;
      background-color: #333;
    }
    &::before {
      right: 0;
    }
    &::after {
      left: 0;
    }
  }

  &.pattern4{
    position: relative;
    z-index: 2;
    background-color: #333;
    border: 2px solid #333;
    color: #fff;
    line-height: 50px;
    &:hover{
      background-color: #fff;
      border-color: #59b1eb;
      color: #59b1eb;
      &::before,
      &::after {
        height: 0;
        background-color: #59b1eb;
      }
    }
    &::before,
    &::after {
      left: 0;
      width: 100%;
      height: 50%;
      background-color: #333;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
  }
  &.pattern5{
    background-color: #333;
    color: #fff;

    &:hover{
      letter-spacing: 5px;
    }
  }
  &.pattern6{
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    line-height: 50px;
    &:hover{
      background-color: rgba(255, 255, 255, .2);
    }
  }
  &.pattern7{
    background-color: #fff;
    border: 2px solid #333;
    color: #333;
    line-height: 50px;
    &:hover{
      border-style: dashed;
    }
  }

  &.pattern8{
    position: relative;
    z-index: 2;
    background-color: #fff;
    border: 2px solid #333;
    color: #333;
    line-height: 48px;
    &:hover{
      &::after{
        opacity: 1;
      }
    }
    &::after{
      top: 4px;
      left: 4px;
      width: 188px;
      height: 42px;
      border: 2px solid #333;
      opacity: 0;

    }
  }


  &.pattern9{
    position: relative;
    z-index: 2;
    background-color: #fff;
    border: 2px solid #333;
    color: #333;
    line-height: 50px;
    overflow: hidden;
    &:hover{
      color: #fff;
      &::after{
        top: 0;
        background-color: #333;
      }
    }
    &::after{
       top: -100%;
       width: 100%;
       height: 100%;
    }
  }

  &.pattern10{
    position: relative;
    z-index: 2;
    background-color: #fff;
    border: 2px solid #333;
    color: #333;
    line-height: 50px;
    overflow: hidden;
    &:hover{
      color: #fff;
      &::after{
        top: 0;
        left: 0;
        background-color: #333;
      }
    }
    &::after{
      top: -100%;
      left: -100%;
      width: 100%;
      height: 100%;
    }
  }

  &.pattern11{
    position: relative;
    z-index: 2;
    background-color: #fff;
    border: 2px solid #333;
    color: #333;
    line-height: 50px;
    overflow: hidden;
    &:hover{
      color: #fff;
      &::after{
        background: #333;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
    &::after{
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-transform: scale(.5);
      transform: scale(.5);
    }
  }


  &.pattern12{
    position: relative;
    z-index: 2;
    background-color: #fff;
    border: 2px solid #333;
    color: #333;
    line-height: 50px;
    overflow: hidden;
    &:hover{
      color: #fff;
      &::after{
        -webkit-transform: scale(2) rotate(180deg);
        transform: scale(2) rotate(180deg);
        background: #333;
      }
    }
    &::after{
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &.pattern13 {
  	position: relative;
  	z-index: 2;
  	border: 2px solid #333;
  	color: #333;
  	line-height: 50px;
  	-webkit-perspective: 300px;
  	perspective: 300px;
  	-webkit-transform-style: preserve-3d;
  	transform-style: preserve-3d;
    &:hover {
    	color: #fff;
      &::after {
      	-webkit-transform: rotateX(0);
      	transform: rotateX(0);
      	opacity: 1;
      }
    }
    &::after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #333;
      -webkit-transform-origin: center top;
      transform-origin: center top;
      -webkit-transform: rotateX(90deg);
      transform: rotateX(90deg);
      opacity: 0;
    }
  }

  &.pattern14 {
  	position: relative;
  	z-index: 2;
  	border: 2px solid #333;
  	color: #fff;
  	line-height: 50px;
  	-webkit-perspective: 300px;
  	perspective: 300px;
  	-webkit-transform-style: preserve-3d;
  	transform-style: preserve-3d;
    &:hover {
    	color: #333;
      &::after {
      	-webkit-transform: rotateX(-180deg);
      	transform: rotateX(-180deg);
      }
    }
    &::after {
    	top: 0;
    	left: 0;
    	width: 100%;
    	height: 100%;
    	background: #333;
    	-webkit-transform-origin: center bottom;
    	transform-origin: center bottom;
    	-webkit-transform: rotateX(0);
    	transform: rotateX(0);
    }
  }

  &.pattern15 {
	position: relative;
	-webkit-perspective: 300px;
	perspective: 300px;
  span{
    &:nth-child(1) {
    	display: block;
    	position: absolute;
    	width: 200px;
    	height: 60px;
    	border: 2px solid #333;
    	text-align: center;
    	line-height: 56px;
    	-webkit-box-sizing: border-box;
    	-moz-box-sizing: border-box;
    	box-sizing: border-box;
    	-webkit-transition: all .3s;
    	transition: all .3s;
    	pointer-events: none;
        &:hover {
          -webkit-transform: rotateX(0deg);
        	-moz-transform: rotateX(0deg);
        	transform: rotateX(0deg);
        }
      }
    &:nth-child(2) {
    	background-color: #fff;
    	color: #333;
    	-webkit-transform: rotateX(0deg);
    	-moz-transform: rotateX(0deg);
    	transform: rotateX(0deg);
    	-webkit-transform-origin: 50% 50% -30px;
    	-moz-transform-origin: 50% 50% -30px;
    	transform-origin: 50% 50% -30px;
        &:hover {
          background-color: #333;
          -webkit-transform: rotateX(-90deg);
          -moz-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
        }
      }
    }
  }

  &.pattern16 {
  	position: relative;
  	background-color: #1abc9c;
  	border-radius: 4px;
  	color: #fff;
  	line-height: 52px;
  	-webkit-transition: none;
  	transition: none;
  	box-shadow: 0 3px 0 #0e8c73;
  	text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
    &:hover {
    	background-color: #31c8aa;
    	box-shadow: 0 3px 0 #23a188;
    }
    &:active {
    	top: 3px;
    	box-shadow: none;
    }
  }

  &.pattern17 {
  	position: relative;
  	background-color: #f7d034;
  	border-radius: 4px;
  	color: #fff;
  	line-height: 52px;
  	-webkit-transition: none;
  	transition: none;
  	box-shadow: 0 3px 0 #f7ba59;
  	text-shadow: 0 1px 1px rgba(0, 0, 0, .4);
    &:hover {
    	top: -4px;
    	box-shadow: 0 7px 0 #f7ba59;
    }
    &:active {
    	top: 3px;
    	box-shadow: none;
    }
    &.g{
      box-shadow: 0 3px 0 #81ef81;
      background-color: #7fbe25;
      &:hover {
        top: -4px;
        box-shadow: 0 7px 0 #81ef81;
      }
    }
    &.a{
      box-shadow: 0 3px 0 #C5C1B6;
      background-color: #E3E1DB;
      &:hover {
        top: -4px;
        box-shadow: 0 7px 0 #C5C1B6;
        color: black;
      }
    }

    &.o{
      box-shadow: 0 3px 0 #EE6D00;
      background-color: #F1862B;
      color: wheat;
      font-size: 1.5rem;
      border: 0;

      &:hover {
        top: -4px;
        box-shadow: 0 7px 0 #EE6D00;
      }
    }
  }



  &.pattern18 {
  	border: 1px solid #15aeec;
  	background-color: #49c0f0;
  	background-image: -webkit-linear-gradient(top, #49c0f0, #2cafe3);
  	background-image: linear-gradient(to bottom, #49c0f0, #2cafe3);
  	border-radius: 4px;
  	color: #fff;
  	line-height: 50px;
  	-webkit-transition: none;
  	transition: none;
  	text-shadow: 0 1px 1px rgba(0, 0, 0, .3);
    &:hover {
    	border:1px solid #1090c3;
    	background-color: #1ab0ec;
    	background-image: -webkit-linear-gradient(top, #1ab0ec, #1a92c2);
    	background-image: linear-gradient(to bottom, #1ab0ec, #1a92c2);
    }
    &:active {
    	background: #1a92c2;
    	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .2);
    	color: #1679a1;
    	text-shadow: 0 1px 1px rgba(255, 255, 255, .5);
    }
  }

  &.pattern19 {
  	position: relative;
  	border-radius: 4px;
  	color: #fff;
  	line-height: 50px;
  	-webkit-box-sizing: content-box;
  	-moz-box-sizing: content-box;
  	box-sizing: content-box;
  	-webkit-transition: none;
  	transition: none;
  	text-shadow: 0px 1px 0px #f56778;
    &:hover {
    	border-bottom: 4px solid #cb5462;
    	background-color: #f56778;
    	background-image: -webkit-linear-gradient(top, #f997b0, #f56778);
    	background-image: linear-gradient(to bottom, #f997b0, #f56778);
    	box-shadow: inset 1px 1px 0 #fbc1d0;
    }
  }
  .button,
  .button:hover {
  	border-bottom: 4px solid #cb5462;
  	background-color: #f56778;
  	background-image: -webkit-linear-gradient(top, #f997b0, #f56778);
  	background-image: linear-gradient(to bottom, #f997b0, #f56778);
  	box-shadow: inset 1px 1px 0 #fbc1d0;
  }
  .button::before,
  .button::after {
  	bottom: -1px;
  	left: -1px;
  	width: 200px;
  	height: 50px;
  	border: 1px solid #ee8090;
  	border-bottom: 1px solid #b84d5a;
  	border-radius: 4px;
  	-webkit-box-sizing: content-box;
  	-moz-box-sizing: content-box;
  	box-sizing: content-box;
  	-webkit-transition: none;
  	transition: none;
  }
  .button::before {
  	height: 48px;
  	bottom: -4px;
  	border-top: 0;
  	border-radius: 0 0 4px 4px;
  	box-shadow: 0 1px 1px 0px #bfbfbf;
  }
  .button:hover {
  	background: #f56778;
  	background-image: -webkit-linear-gradient(top, #f56778, #f997b0);
  	background-image: linear-gradient(to bottom, #f56778, #f997b0);
  	color: #913944;
  	text-shadow: 0px 1px 0px #f9a0ad;
  }
  .button:active {
  	bottom: -4px;
  	margin-bottom: 4px;
  	border: none;
  	box-shadow: 1px 1px 0 #fff, inset 0 1px 1px rgba(0, 0, 0, .3);
  }
  .button:active::before,
  .button:active::after {
  	border: none;
  	box-shadow: none;
  }

  &.pattern20 {
  	background-color: rgba(255, 255, 255, .2);
  	border-radius: 4px;
  	color: #fff;
  	box-shadow: 0 3px 3px rgba(0, 0, 0, .2), inset 0 1px 1px rgba(255, 255, 255, .7);
  	text-shadow: 0px 1px 0px rgba(0, 0, 0, .4);
    &:hover {
    	background-color: rgba(255, 255, 255, .3);
    }
  }
}



.button.register{
    @extend .button.pattern17.a;
    &:hover{
      color: white;
    }
    color:white;
    background-color: #4e493f;
}
.button.answer{
  @extend .button.pattern17.a;
  @include font-set(5,14,1);
  height: 50px;
  width: 80%;
  max-width: 300px;
  margin: 0 auto;
  margin-bottom: 50px;
  display: block;
  border-radius: 2px;
  box-shadow: 0 3px 0 #d3cebf;
}

.button.teacher-answer{
  @extend .button.pattern17.o;
  @include font-set(5,14,1);
  color: wheat;
  height: 50px;
  width: 80%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
  border-radius: 2px;
  box-shadow: 0 3px 0 #d3cebf;
  padding-top: 15px;
  margin-bottom: 30px;
}


.button.question-back{
  @extend .button.pattern17.a ;
  width:300px;
  height:40px;
  line-height:24px;
  margin:22px auto;
  font-size: 1.5rem;
  border: 0;
}

.button.question-post{
  @extend .button.pattern17.o ;
  width:300px;
  height:40px;
  line-height:24px;
  margin:22px auto;
}

.teacher-answer-count{
  padding: 5px 5px;
  background-color: red;
  border-radius: 25%;
  color: white;
}

.autoConfirmBack{
    @extend .button.pattern17.a;
    width: 300px;
    height: 40px;
    line-height: 24px;
    margin: 22px auto;
}
.submit_button{
    @extend .button.pattern17.a;
    color:white;

}
