.news-top-visual{
	li{
		width:400px;
		max-height:400px;
		position:relative;
		img{
			width:100%;
		}
		.visual-footer{
			position: absolute;
		    bottom: 0px;
		    width: 100%;
		    text-align: left;
		    padding: 50px 33px;

		    height: 156px;
		    background: transparent url(./images/bg/gradation_black.png) repeat-x;
		    background-size: 100% 100%;
		    background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.6)));

		    h2{
				color: #ffffff;
				font-size: 16px;
		    }
		    p{
				color: #ffffff;
				font-size: 14px;
		    }
		}
	}
}

a.detail_shop_link {display:inline-block;width:auto;padding:20px;text-align:center;background:#7ebd24;color:#fff !important;border-radius:6px;box-shadow:0px 2px 0 rgba(0,0,0,0.2);}
a.detail_shop_link:hover {text-decoration:none !important;opacity:0.6;}
a.detail_shop_link img {display:inline;padding:0 20px 0 0;opacity:1 !important;    opacity: 1 !important;
    width: 50px !important;
}
a.detail_shop_link {
    display: inline-block;
    width: auto;
    padding: 20px;
    text-align: center;
    background: #7ebd24;
    color: #fff !important;
    border-radius: 6px;
    box-shadow: 0px 2px 0 rgba(0,0,0,0.2);
}

.items.news{
   min-width: 100%;
   max-width: 100%;
   width: 100%;
   padding-top:20px;
   clear: both;
   border-bottom: 1px solid #c0c0c0;
   min-height: 188px;

    @include mqSP {
	   min-width: 100%;
	   max-width: 100%;
	   width: 100%;
	   min-height: 130px;
	}

	.image{
		width: 150px;
	    float: left;
	    img{
	    	width:100%;
	    }
	    @include mqSP {
			width: 100px;
		}
	}
	.contents{
		width: 270px;
		display:inline-block;
		text-align:left;
 	    width : -webkit-calc(100% - 150px) ;
 	    width : calc(100% - 150px) ;
 	    padding-left:20px;

	    @include mqSP {
		   width : -webkit-calc(100% - 100px) ;
		   width : calc(100% - 100px) ;
	    }

	    .description{
	    	max-height: 47px;
	    	overflow:hidden;
		    margin-bottom: 20px;

		    @include mqSP {
		    	display:none;
		    }
	    }
	}
	h1,h2{
		margin: 0 0 3% 0;
	    @include font-set(1,16,2);
	    max-height:75px;
	    overflow: hidden;
	}
	p{
	      @include font-set(5,14,1);
	}
	> div{
		text-align: left;
		background-color: transparent;
	}
}

.side-bar-right{
  .items.news{
    min-height: 94px;
    margin-left: 22px;
    margin-right: 22px;
    width:270px;
    min-width:270px;

	@include mqSP {
	   padding-top:20px;
	   clear: both;
	   border-bottom: 1px solid #c0c0c0;
	   min-width: 100%;
	   max-width: 100%;
	   width: 100%;
	   min-height: 130px;
	   margin-left: 0px;
	   margin-right: 0px;
	}

   .image{
		width: 60px;
	    @include mqSP {
			width: 100px;
		}
	}
	.contents{
		width: 270px;
		display:inline-block;
		text-align:left;
 	    width : -webkit-calc(100% - 60px) ;
 	    width : calc(100% - 60px) ;
 	    padding-left:20px;

 	    h1{
	 	  height:40px;
	 	  line-height:2.1rem;
 	    }
	    @include mqSP {
		   width : -webkit-calc(100% - 100px) ;
		   width : calc(100% - 100px) ;
		}

	    .description{
	    	display:none;
	    }
	}
  }
}



#news_list{
	.items.news:last-child{
		border-bottom: 0px;
	}
}


#news_sub_list{
	.items.news{
		.image{
			&::before {
				content: "";
			    top: 19px;
			    left: 0px;
			    border-bottom: 2em solid transparent;
			    border-left: 2em solid #F1862B;
			    position: absolute;
			    z-index: 100;
			}
			&::after {
			    content: "1";
			    display: block;
			    top: 20px;
			    color: #fff;
			    left: 4px;
			    position: absolute;
			    z-index: 101;

			}
		}

			&:nth-of-type(1) {
				.image{
					&::after {
					    content: "1";
				    }
			    }
			}
			&:nth-of-type(2) {
				.image{
					&::after {
					    content: "2";
				    }
			    }
			}
			&:nth-of-type(3) {
							.image{
					&::after {
					    content: "3";
				    }
			    }


			}
			&:nth-of-type(4) {
				.image{
					&::after {
					    content: "4";
				    }
			    }
			}
			&:nth-of-type(5) {
							.image{
					&::after {
					    content: "5";
				    }
		    }
		}

	}

}


.items.news.detail-header{
	h1{
	    @include font-set(1,24,2);
	}

	.lead{
		.description{
		    height: 78px;
		    overflow: hidden;
		    padding-top: 8px;
		}
	}

	.info{
		width: 100%;
		@extend .clearfix;

		.category {
			float:left;	
		}

		.access {
			float:right;	
		}

		ul{
		    color: #74726C;
		    font-size: 10px;
		    font-size: 1rem;
		    line-height: 16px;
		    line-height: 1.6rem;

		    li{
			    display: inline-block;
			    padding: 3px 12px;
			    margin: 3px 2px;
			    border-radius: 3px;
			    background-color: #e3e1db;
				a:hover{
					@include font-color(2);
				}
			}
		}

	}
}
