/* 
   !@extend として利用するものを記述します
   基本的には mixin 側ではるので、本内容は これ以上増えない前提
*/

/*
  clearfix ;
*/
.clearfix {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.user-sel-none{
    user-select: none; /* CSS3 */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Safari、Chromeなど */
    -ms-user-select: none; /* IE10かららしい */
}

/*
 テキストの文字数制限
*/
.ellipsis{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}

/*
 テキストの文字数制限
*/
.ellipsis-y{
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
}

/*
 pc のみ 表示するもの
*/
.pc-only {
    display:block ;
    @include mqSP {
      display:none !important;
    }
}

/*
 sp のみ 表示するもの
*/
.sp-only {
    display:none;
    @include mqSP {
      display:block ;
    }
}


/*
 sp のみ 表示するもの
*/
.sp-show {
    @include mqSP {
      display:block;
    }
}


/*
 sp のみ 表示するもの
*/
.sp-hide {
    @include mqSP {
      display:none;
    }
}

