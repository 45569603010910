.items.review.shops{
	margin-top:22px;
	text-align:left;
	background-color:transparent;

	width: 100%;
    max-width: 100%;
    
    height:auto;
    min-height:auto;
    padding:0;
    @extend .clearfix;
    margin: 22px 0 0 0;
        

    .page-title{
		@extend .clearfix;
        color: #50463c;
	    font-size: 20px;
	    font-size: 2rem;
	    line-height: 32px;
	    line-height: 3.2rem;
	    padding: 10px;
	    border-bottom: 1px solid #c0c0c0;
	    padding-bottom: 11px;
	    background: transparent;

    	h3{
		   width : -webkit-calc(100% - 150px) ;
		   width : calc(100% - 150px) ;
		   float:left;
    	}

    	a{
    		width:90px;
    		float:right;
    	}
    }
    .item{
	    padding: 10px 12px;
	    background: transparent;
	    border-bottom: 1px solid #c0c0c0;
	    padding-bottom: 8px;
    }

	.title {
		@extend .clearfix;
		@include font-set(1,14,2);
	    border-bottom: 1px solid #c0c0c0;

		span {
			@include font-set(1,14,1);
		}
		.icon-human{
		   width : -webkit-calc(100% - 150px) ;
		   width : calc(100% - 150px) ;
		   float:left;

			.thumbnail{
				width: 25px;
				float: left;
			}

			.name {
				font-weight: bold;
				display: inline-block;
				text-align: left;
				width: -webkit-calc(100% - 175px);
				width: calc(100% - 175px);
				padding-left: 10px;
				padding-top: 5px;
				border-bottom: 0px solid #c0c0c0;
				@include font-set(1,14,2);


				@include mqSP {
					width: 75%;
				}

			}

		}
		.info{
    		width:150px;
    		float:right;
			@include font-set(1,14,2);
		}
	}
	.content {
		background-color:transparent;
		@include font-set(5,14,1);
	    padding-top: 11px;
	}

	.center{
		padding : 10px;
		text-align: center;
	}
}