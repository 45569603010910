.items{
  position: relative;
  -webkit-transition-duration: .8s;
  -moz-transition-duration: .8s;
  -o-transition-duration: .8s;
  -ms-transition-duration: .8s;
  transition-duration: .8s;

  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  transition-timing-function: ease-out;

  >div,>li{
    display:inline-block;
    >a {
      >div{
        width: 100%;
        height: 100%;

        img{
          border-radius: 6px 6px 0 0;
        }
        p{
          padding: 4px 16px;
        }
        &:hover{
          opacity: 0.6;
        }
      }
    }
  }

  &.pattern1 {
    margin: 10px 8px;
    width: 300px;
    height: 400px;
    border-radius: 6px;
    box-shadow: 0px 2px 4px #aaa;
    display: inline-block;
    img {
      width: 300px;
      height: 200px;
      border-radius: 6px 6px 0 0;
    }
    h2 {
      font-size: 18px;
      padding: 4px 16px;
    }
    p {
      padding: 4px 16px;
    }
    img:hover {
      opacity: 0.6;
      color: #f00;
    }
    h2:hover {
      color: #f00;
    }
  }

  &.pattern2 {
    width: 300px;
  	height: 400px;
  	vertical-align: top;
  	margin: 10px 8px;
  	border-radius: 6px;
  	box-shadow: 0px 2px 4px #aaa;
  	display:inline-block;
    &.card02 {
    	width: 100%;
    	height: 100%;
      &:hover {
      	opacity: 0.6;
      }
    }
    h2 {
    	font-size: 18px;
    	padding: 4px 16px;
    }
    p {
    	padding: 4px 16px;
    }
    img {
    	width: 300px;
    	height: 200px;
    	border-radius: 6px 6px 0 0;
    }
  }

  &.pattern3 {
	width: 300px;
	height: 400px;
	vertical-align: top;
	margin: 10px 8px;
	border-radius: 6px;
	box-shadow: 0px 2px 4px #aaa;
	display: inline-block;
  &.card {
  	width: 100%;
  	height: 100%;
    img {
    	width: 300px;
    	height: 200px;
    	border-radius: 6px 6px 0 0;
    	-webkit-transition: all 0.6s ease;
    	transition: all 0.6s ease;
    }
  }
  h2 {
    font-size: 18px;
  	padding: 4px 16px;
  }
  p {
  	padding: 4px 16px;
  }
  img {
    &:hover {
    	-webkit-transform: rotateY(180deg);
    	transform: rotateY(180deg);
    }
  }
  &:hover {
  	opacity: 0.6;
  }
}

&.pattern4 {
  width: 300px;
  height: 400px;
  vertical-align: top;
  margin: 10px 8px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #aaa;
  display: inline-block;
  &.card {
    width: 100%;
    height: 100%;
  }
  h2 {
    font-size: 18px;
    padding: 4px 16px;
  }
  p {
    padding: 4px 16px;
  }
  img {
    width: 300px;
    height: 200px;
    border-radius: 6px 6px 0 0;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
  }
  &:hover {
    opacity: 0.6;
    img{
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg);
    }
  }
}

&.pattern5 {
  width: 300px;
  height: 400px;
  vertical-align: top;
  margin: 10px 8px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #aaa;
  display: inline-block;
  position: relative;
  &.card {
    width: 100%;
    height: 100%;
  }
  h2 {
  	font-size: 18px;
  	padding: 4px 16px;
  }
  p {
  	padding: 4px 16px;
  }
  &.caption {
  	font-size: 24px;
  	text-align: center;
  	padding-top: 80px;
  	color: #fff;
  }
  img {
  	width: 300px;
  	height: 200px;
  	border-radius: 6px 6px 0 0;
  }
  &.mask {
  	width: 300px;
  	height: 200px;
  	position: absolute;
  	top: 0;
  	left: 0;
  	opacity: 0;
  	border-radius: 6px;
  	background: rgba(0,0,55,0.5);
  	-webkit-transition: all 0.2s ease;
  	transition: all 0.2s ease;
  }
  &:hover {
    &.mask {
    	opacity: 0.7;
    }
  }
}

&.pattern6 {
  width: 300px;
  height: 400px;
  vertical-align: top;
  margin: 10px 8px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #aaa;
  position: relative;
  display: inline-block;
  h2 {
    font-size: 18px;
    padding: 4px 16px;
  }
  p {
    padding: 4px 16px;
  }
  img {
    width: 300px;
    height: 200px;
    border-radius: 6px 6px 0 0;
  }
  &.mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 6px;
    background: rgba(0,0,55,0.4);
  }
  &:hover {
    &.mask {
      opacity: 0.7;
    }
  }
}

&.pattern7{
  width: 300px;
  height: 400px;
  vertical-align: top;
  margin: 10px 8px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #aaa;
  display: inline-block;
  &.card {
    width: 100%;
    height: 100%;
  }
  h2 {
    font-size: 18px;
    padding: 4px 16px;
  }
  p {
    padding: 4px 16px;
  }
  img {
    width: 300px;
    height: 200px;
    border-radius: 6px 6px 0 0;
  }
  &:hover {
    box-shadow: 0px 6px 8px #333;
    opacity: 0.6;
  }
}

&.pattern8 {
  width: 300px;
  height: 400px;
  vertical-align: top;
  margin: 10px 8px;
  border-radius: 6px;
  box-shadow: 0px 6px 8px #999;
  position: relative;
  display: inline-block;
  &.card {
    width: 100%;
    height: 100%;
  }
  h2 {
    font-size: 18px;
    padding: 4px 16px;
  }
  p {
    padding: 4px 16px;
  }
  img {
    width: 300px;
    height: 200px;
    border-radius: 6px 6px 0 0;
  }
  &:hover {
    box-shadow: 0px 1px 2px #aaa;
    opacity: 0.6;
  }
}

}
