.contents{
  @include fzauto(14);
  font-weight : 100 ;
}
.contents >section{
      padding-top: 50px;
      padding-bottom: 50px;

  &.sp-padding{
    @include mqSP{
      padding-top: 0;
      padding-bottom: 0;
    };
  }
}

section{
    @extend .clearfix;
}

.map-select{
    .inner{
        margin: 7% auto;
    }
}

section.fixed{
     text-align:left;
     line-height:300%;
     padding-top: 20px;
     padding-bottom: 20px;

     h2{
        background: #e3e1db none repeat scroll 0 0;
        font-size: 140%;
        color: #4c483f;
        letter-spacing: 1px;
        line-height: 1.1em;
        padding: 16px 10px;
        text-align: center;
        margin-bottom: 20px;
     }

     h3{
         border-bottom: 1px solid #4c483f;
         color: #4c483f;
         font-size: 110%;
         font-weight: bold;
         margin-bottom: 10px;
         margin-top: 10px;
         padding: 10px 0 5px 10px;
     }

     img {
         width:100%;
     }
     #columnsl .columphoto {
        display: block;
        float: none;
        margin: 0 auto 2%;
        width: 90%;
    }

   }
