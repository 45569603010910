.shop.side-bar-contents{
   width : -webkit-calc(100% - 318px) ;
   width : calc(100% - 318px) ;
}
.side-bar-contents{
   width: 680px;
   width : 98% ; /* IE8以下とAndroid4.3以下用フォールバック */
   width : -webkit-calc(100% - 320px) ;
   width : calc(100% - 320px) ;
   float: left;
   min-width: 400px;

   .origin{
     text-align:left;
     line-height:300%;
     padding-top: 20px;
     padding-bottom: 20px;
     @include mqSP{
       line-height:130%;

       iframe {
         width: 100%;
       }

     };

     h2{
        background: #ffffff none repeat scroll 0 0;
        font-size: 172%;
        letter-spacing: 1px;
        line-height: 1.1em;
        padding: 22px 12px;
        text-align: center;

     }

     h3{
         border-bottom: 1px solid #ffa201;
        border-left: 10px solid #ffa201;
        font-size: 120%;
        font-weight: bold;
        margin-bottom: 10px;
        padding: 10px 0 10px 10px;
     }

     img {
         width:100%;

       &.auto{
         width:auto;
         max-width: 100%;
       }
     }
     #authored_works {
       padding: 3%;

       img {
         float: left;
       }
       a img {
         width: 130px;
         padding-right: 10px;
       }
     }
     #columnsl .columphoto {
        display: block;
        float: none;
        margin: 0 auto 2%;
        width: 90%;
    }

   }
   @include mqSP {
	   min-width: 100%;
	   max-width: 100%;
	   width: 100%;
   }

}

.side-bar-right{
	width:300px;
	float:right;
  border-left: 1px dotted #c0c0c0;

    @include mqSP {
	   min-width: 100%;
	   max-width: 100%;
	   width: 100%;
      border-left: 0px;
     }

}


.shop.side-bar-right{
  border-left: 0px dotted #c0c0c0;
}
