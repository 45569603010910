h1{
    @include fzauto(26);

    @include mqSM {
        @include fzauto(24);
    }

    @include mqXS {
        @include fzauto(22);
    }

}
h2{
    @include fzauto(24);

    @include mqSM {
        @include fzauto(22);
    }

    @include mqXS {
        @include fzauto(20);
    }
}
h3{
    @include fzauto(18);
}
h4{
    @include fzauto(16);
}
h5{
    @include fzauto(14);
}

section>h1{
    @include fzauto(22);
}
section>h2{
    @include fzauto(20);
    padding-bottom: 40px;
}
section>h3{
    @include fzauto(18);
}
section>h4{
    @include fzauto(16);
}
section>h5{
    @include fzauto(14);
}


article>h1{
    @include fzauto(16);
}
article>h2{
    @include fzauto(16);
}
article>h3{
    @include fzauto(15);
}
article>h4{
    @include fzauto(14);
}
article>h5{
    @include fzauto(13);
}

&.heading{
    text-align:center;
    padding: 13px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
&.heading-line{
    text-align:center;
    padding: 13px;
    border-top: 1px solid #87847F;
    border-bottom: 1px solid #87847F;

    &.no-b{
      background-color:transparent !important;
    }
}

