.shops,.shop-recommends-list{
	.bx-wrapper .bx-pager.bx-default-pager a {
		background: #a9a694;
	}

	.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
		background: #ffffff;
	}
}

.shop-recommends-list{
	.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
		background: black;
	}
}

.shop .shop-h{
	    border-bottom: 1px solid black;
	    text-align: left;
	    width: 100%;
	    padding: 6px;

	}

.shops{
	.title.address{
	    padding-top: 5px;
		padding-bottom: 5px;
		@include font-set(5,12,1);
		width:100%;
		@extend .ellipsis;
	}
	ul.category{
		padding-bottom: 5px;
		@extend .clearfix;
		width:100%;
		@extend .ellipsis;
		height:22px;
		> li {
			float:left;
			@include font-set(1,12,2);
			&:after{
				content: "/";
			}
			&:last-child{
				&:after{
					content: "";
				}
			}

		}
	}
	ul.info{
		@extend .clearfix;
		@include font-set(5,12,1);
		margin:3px 0 ;
		padding-bottom: 10px;
		li {
			display:inline-block;
			margin:0 4px;

			&:first-child{
				margin-left:0px;
			}

			&:last-child{
				margin-right:0px;
			}
		}
		li.info-star{
		    float: right;
			@extend .clearfix;
		}
	}

	ul.star{
		li {
			color :#FFC001;

			display:inline-block;
			margin:0;

			&:first-child{
				margin-left:0px;
			}

			&:last-child{
				margin-right:0px;
			}
		}
		.number{
				font-size:100%;
			}
	}

	.course {
		display:inline-block;
		margin: 0px 8px;
	    border-radius: 3px;
	    background-color: #fffbd4;

		ul.price{
			@extend .clearfix;
			li {
				display:inline-block;
				&.quepon{
				    border-radius: 5px;
				    background: red;
						color: white;
				    font-size: 10px;
				    max-width: 30px;
				    text-align: center;
				    line-height: 11px;
				    padding: 3px;
						vertical-align: middle;
						.price{
							display: table-cell;
							vertical-align: middle;
							height: 24px;
							text-align: center;
							width: 47px;
							span.wide{
								font-weight: bold;
							}
						}
					&.float{
						float: left;
						margin-right: 1.5%;
					}
				}

				&.normalprice{
				    @include font-set(5,12,1);
				    text-align: center;
				    line-height: 11px;
				}

				&.sellingprice{
					@include font-set(3,14,2);
				    padding: 3px 4px;
				    text-align: left;
				    line-height: 11px;
						span.wide{
							@include font-set(3,16,2);
							font-weight: bold;
						}
				}

				.coursetitle{
					@include font-set(1,14,2);
					font-weight: bold;
					width: 280px;
				}

				&:first-child{
				}

				&:last-child{
				}
			}
			.description{
				.coursetitle{
					@include font-set(1,14,2);
					font-weight: bold;
					line-height: 3.0rem;
					width: 280px;
				}
			}
		}
	}

	.tags {
		position:relative;
		ul.tag-area{
			@include font-set(5,10,1);
			float: left;
			margin:5px 0px;
			li{
				display:inline-block;
				padding: 3px 5px;
		    margin: 3px 2px;
		    border-radius: 3px;
				background-color: #f5f3ee;
				@include mqSP{
					padding: 3px;
				};

				&.act{
					@include font-set(2,10,1);

				}
			}
		}
		.staff {
			position:absolute;
			right:12px;
			top :10px;
			@include font-set(2,10,1);
		}
	}



	.human{
		@extend .clearfix;
		@include font-set(2,14,2);
		height: 70px;
		margin-top: 15px;
		margin-bottom: 15px;
		.thumbnail{
			@extend .thumbnail.circle;
			width: 60px;
		float:left;
		}
	}


	.icon-human{
		@extend .clearfix;
		height: 30px;

		.thumbnail{
			@extend .thumbnail.circle;
			width: 25px;
			float:left;
		}
	}

	.buttons {
		display: inline;
		a.button.favorite {
		    margin-top: 0px;
			background-color: #82be28;
			color:white;
		}
		a.button.detail {
		    margin-top: 0px;
			background-color: #50463c;
			color:white;
		}
	}
}


.items.shops{
    width: 100%;
    max-width:320px;
    float: left;
    text-align: left;
    background-color: white;
    margin: 0 5px;
    margin-bottom: 25px;
    box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
    padding-bottom: 19px;
    min-height:690px;

		&.pickup{
    	border: 1px solid #f08228;
		}

	@media (max-width: 1001px)
	{
		max-width: 500px;
		width: 48%;
  }

	@media (max-width: 670px)
	{
		width: 98%;
		max-width:initial;
	}

	img.pickup{
		width: 70px;
		position: absolute;
		top: -1%;
		right: 0%;
	}

	h1,h2 {
		@include font-set(1,18,2);
		@extend .ellipsis;
		width:100%;
	}

	&>*{
		 padding:0 12px;
		 display: block;
	}

	.human{
		padding-top:12px;
		padding-bottom:12px;
		.add{
			@include font-set(1,12,1);
			a{
				text-decoration: underline;
    		color: #3f48cc;
			}
		}
		.title{
		    max-height: 45px;
		    overflow: hidden;
		    margin: 4px;
		    padding: 0 2px;
				font-weight: bold;
				display: table-cell;
				vertical-align: middle;
				height: 66px;
			  @include font-set(2,14,2);
			a{
				color: inherit;
			}
			p{
				overflow: hidden;
				height: 45px;
			}
		}
	}

	.course {
    padding: 6px 12px;
		overflow: hidden;
		height: 63px;
		margin-bottom:5px;
		@include mqSP {
			height: 63px;
		};
    }

	.etc{
		padding-top : 5px;
		padding-bottom : 10px;
		p{
			width: 100%;
		    height: 22px;
	    	@include font-set(1,14,1);
			@extend .ellipsis;
		}
	}

	&>.bx-wrapper{
		padding:0px;
	}

	.buttons{
	 	@extend .clearfix;
		.button{
			width: 50%;
		    float: left;
		    border-radius: 0;
		}
	}

	&.recommends{

		&.list{
			float: none;
			margin: 0;
			min-height:140px;
			border:1px solid #c0c0c0;
			border-top:0px;

			@include mqSP{
				min-height: 220px;
			};
			.course{
				height: 60px;
				padding: 6px;
				width: 100%;
				margin: 6px 0 0 0;
			}
			.list-title{
				@include font-set(2,14,2);
		    width: 100%;
		    float: left;
			}
		}

		.shop-image-area{
			overflow: hidden;
	    width: 100px;
	    height: 100px;
	    position: relative;
	    float: left;
	    margin-top: 10px;
	    margin-left: 20px;
	    margin-right: 20px;
			.thumbnail{
				img{
					position: absolute;
			    width: 250px;
			    top: 50%;
			    left: 50%;
			    transform: translate(-50%, -50%);
					max-width: none;
				}
			}
		}
		.info{
			padding:8px 12px;
		}
		.address{
			@include font-set(5,12,1);
		}
		.name{
			@include font-set(1,16,2);
			font-weight: bold;
		}
		.category{
			@include font-set(1,12,1);
			width: 70%;
		}

		.info-photo{
			@include font-set(5,12,1);
			float: left;
			padding-right:10px;
		}
		.info-kuchikomi{
			@extend .info-photo;
		}
		.info-advice{
			@include font-set(5,12,1);
		}

	}
}

.detail.card.shops{
	background: white;
	text-align:left;
    margin-top: 30px;
    text-align: left;
    padding: 16px;
	display:inline-block;
	width:100%;
	box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;


	@include mqSP{
		margin-top:0px;
	}

	.ul-info{
		border-bottom: 1px solid silver;
	    margin-bottom: 18px;
	    padding-bottom: 18px;
	}
	ul.info {
		border-bottom: 1px solid #c0c0c0;
		@include mqSP{
			border-bottom : 0px;
		}
	}

	ul.category {
	   width : -webkit-calc(100% - 150px) ;
	   width : calc(100% - 150px) ;
	   float:left;
	}
	.catchcopy{
		.lead {
			@include font-set(5,13,1);
			background-color: #f08228;
		    margin-left: -17px;
		    margin-right: -17px;
		    padding: 10px 10px;
		    color:white;
		    position:relative;
	    	&:before{
	    		display: block;
			    position: absolute;
			    top: -10px;
			    left: 40px;
			    border: solid transparent;
			    border-width: 0 8px 10px 8px;
			    border-bottom-color: #f08228;

			    content: "";
	    	}

		}
		.detail {
		    padding-top: 12px;
			@include font-set(1,14,1);
		}

	}

	.star{
		font-size:140%;
		width:150px;
		text-align:right;
		display:inline-block;

		.number{
			font-size:100%;
		}
	}

	.detail-tags{
		ul.tag-area{
			@include  font-set(5,10,1);
			li{
				display: inline-block;
    		padding: 3px 12px;
    		margin: 3px 2px;
    		border-radius: 3px;
    		background-color: #f5f3ee;
				&.act{
					@include font-set(2,10,1);

				}
			}
			}
		}

	.female-staff{
		@include font-set(2,10,1);
	}
	.info-photo{
		text-align: right;
		float: right;
		padding: 0px 5px;
	}
	.info-kuchikomi{
		text-align: right;
		float: right;
		padding: 0 0 0 5px;
	}

	.dl-s{
		@extend .clearfix;
	    width: 100%;
        border-bottom: 1px solid silver;
        padding-top: 12px;
	    padding-bottom: 12px;

		dt{
		  float:left;
			width: 20%;
		  padding-right:12px;
		}
		dd{
			float:left;
			width: 80%;
		}
	}

	.human{
		@extend .clearfix;
		@include font-set(2,14,2);
		height: 90px;
		margin-top: 15px;
		margin-bottom: 15px;
		position: relative;
		@include mqSP{
			height: 100px;
		};
		.thumbnail{
			@extend .thumbnail.circle;
			width: 70px;
			float:left;
		}
		.name {
			display: inline-block;
			text-align: left;
			width: -webkit-calc(100% - 170px);
			width: calc(100% - 170px);
			padding-left: 10px;
			position: absolute;
			top: 15px;
			@include font-set(1,16,1);
			@include mqSP {
				@include font-set(1,14,1);
				width: -webkit-calc(100% - 130px);
				width: calc(100% - 130px);
			};
		}
		.advice {
			display: inline-block;
			text-align: left;
			width: -webkit-calc(100% - 170px);
			width: calc(100% - 170px);
			padding-left: 10px;
			position: absolute;
			top: 40px;
			@include font-set(1,14,1);
			@include mqSP {
				width: -webkit-calc(100% - 130px);
				width: calc(100% - 130px);
			};
		}
		.favorite {
			display: inline-block;
			float:right;
			width: 100px;
			text-align: center;
			border-left: 1px solid silver;
			padding: 5px 0;
			@include mqSP {
				width: 60px;
				border-left: 0px;
			};
			a {
				@include font-set(1,20,1);
			}
			a.favorited {
				@include font-set(2,20,1);
			}
			p {
				@include font-set(1,14,1);
				@include mqSP {
					@include font-set(1,12,1);
				};
			}
		}
		.add{
			@include font-set(1,12,1);
	    text-align: left;
	    position: absolute;
	    top: 70px;
			};
			a{
				text-decoration: underline;
        color: #3f48cc;
			}
		}
}

.shop-recommends-list{
	.catchcopy{
		height: 50px;
		background-color: #ab9787;
		h2{
			@include font-set(9,16,2);
			font-weight: bold;
			text-align:left;
			padding-top: 15px;
			padding-left: 20px;
		}
	}
}

.detail.moview.shops{
	>*{
		padding-left: 16px;
		padding-right: 16px;
	}
	text-align: left;
	width: 100%;
	max-width: 100%;
	height: auto;
	min-height: auto;
	padding: 0;
	margin: 22px 0 0 0;
	.youtube{
		position: relative;
		margin: 5% 10%;
		padding-top: 56.25%;
		iframe{
			position: absolute;
			top: 0;
			right: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
}

.detail.card.shops.etc{
	padding:0px;
	box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
	>*{
	    padding-left: 16px;
	    padding-right: 16px;
	}

	.title{
		font-weight: bold;
		@include font-set(1,16,2);
		margin-top:22px;
	}

	.quepon-title{
		font-weight: bold;
		@include font-set(1,16,2);
		padding-top: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid silver;
	}
	.title.address{
		font-weight: normal;
		@include font-set(1,14,2);
		margin-top:5px;
		margin-bottom:10px;
	}
	.businessmap{
		position: relative;
		.table-sheet{
			width: 60%;
			display: inline-block;
			position: relative;
			top: 5px;
			left: -15px;

			@include mqSP{
				width: 100%;
			}

		}
		.holiday{
			display: inline-block;
			width: 30%;
			padding: 0px 0px 50px 25px;
			border-left: 1px solid silver;
			position: absolute;
			top: 12px;

			@include mqSP{
				width: 100%;
				position:relative;
				border:0px;
			}

		}
		table{
			.first{
					border-bottom: 1px solid silver;
			}
			td{
				text-align: center;
				border :0px;
				span{
					&.n-0{
						color:#e82e37;
					}
					&.n-1{
						color:#90c742;
					}
					&.n-2{
						color:#4c483f;
					}
				}
			}
		}

	}

	.contents {
		border-bottom : 1px solid #c0c0c0 ;
		&:last-child{
			border-bottom : 0px solid #c0c0c0 ;
		}
	}

	.dtl-course {
		display:inline-block;
		border-radius: 3px;
		padding: 10px;
		width: 100%;
		background-color: #fffbd4;
		margin: 10px 0;
		.price{
			@extend .clearfix;
				.quepon{
					border-radius: 5px;
					background: red;
					color: white;
					font-size: 14px;
					width: 50px;
					text-align: center;
					line-height: 15px;
					padding: 5px;
					float:left;
					margin-right: 10px;
					@include mqSP{
						width: 46px;
						padding: 3px;
					};
				}
				.free{
					padding: 12px 5px;
					@include mqSP{
						padding: 10px 5px;
					};
				}

				.normalprice{
					@include font-set(5,14,1);
					line-height: 11px;
					padding-top: 5px;
					@include mqSP{
						padding: 0;
					};

					@include mqXS {
						@include font-set(5,12,1);
					}

					.wide{
						@include font-set(3,18,1);
						font-weight: bold;
						line-height: 0;
						@include mqXS {
							@include font-set(3,12,1);
						}
					}

				}

				.sellingprice{
					@include font-set(3,20,2);
					padding: 3px 4px;
					text-align: left;
					line-height: 11px;
					letter-spacing: -0.4px;

					@include mqXS {
						@include font-set(3,16,2);
					}

				}
				.coursetitle{
					font-weight: bold;
					padding-top: 1%;
					@include font-set(1,14,2);
					@include mqSP{
						text-overflow: ellipsis;
				    white-space: nowrap;
				    overflow: hidden;
					};
					&.pt-10px{
						padding-top: 10px;
					}
				}

				&:first-child{
				}

				&:last-child{
				}
		}

		.end-date{

			font-weight: bold;
			background-color: rgba(79, 81, 85, 0.13);;
			width: 180px;
			text-align: center;
			@include font-set(3,12,2);
			li{
				padding: 7px;
			}
		}

		.coursedescription{
			padding-top: 10px;
			padding-bottom: 10px;
			@include font-set(1,14,2);
		}
	}


	.googlemap{
		padding:0;
	}
}



.detail.menu.shops{
	margin-top:22px;
	text-align:left;
	.menu{
		background-color:white;
		border : 1px solid #c0c0c0 ;
		box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
		&.first{
			margin-bottom: 15px;
			padding-bottom:5px;
			li.menu-title{
			    padding: 23px 0;
    			font-weight: bold;

				p {
					@include font-set(1,16,1);
					@extend .clearfix;
					width: 100%;
					float:left;
					}
				p.price {
				 	float:right;
					@include font-set(2,14,1);
					span {
						@include font-set(2,14,2);
					}
				}
			}
		}

		.title{
			@include font-set(1,20,2);
			margin:12px;
			border-bottom: 1px solid #c0c0c0;
		    padding-bottom: 11px;
		}

		.contents{
			margin-left:20px;
			margin-right:20px;
			li.menu-title{
			    padding: 17px 0;
    			font-weight: bold;
				@extend .clearfix;
				p {
					@include font-set(1,16,2);
					@extend .clearfix;
				    width : calc(100% - 120px) ;
					float:left;
				}
				p.price {
				 	float:right;
					width:120px;
					text-align:right;
					@include font-set(2,14,1);
					span {
						@include font-set(2,14,2);
					}
				}
			}

			.menu-description{
				padding-bottom:15px;
				@include font-set(5,14,1);
				width: 100%;
				border-bottom : 1px solid #c0c0c0 ;
				img {
					width: 100px;
					float:left;
				}
				.content.has-image {
					display: inline-block;
					text-align: left;
					width: -webkit-calc(100% - 100px);
					width: calc(100% - 100px);
					padding-left: 10px;
				}
			}

			li:last-child {
				border-bottom : 0px solid #c0c0c0 ;
			}
		}
	}
}


.detail.staff.shops{
	margin-top:22px;
	text-align:left;
	background-color:white;
	border : 1px solid #c0c0c0 ;
	box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
	.title{
		@include font-set(1,20,2);
		margin:12px;
		border-bottom: 1px solid #c0c0c0;
	    padding-bottom: 11px;
	}

	.human{
		width: 100%;
		margin-left :20px;
		.thumbnail{
			width:80px;
			height:80px;
		}

		.name {
			font-weight: bold;
			display: inline-block;
			text-align: left;
			width: -webkit-calc(100% - 80px);
			width: calc(100% - 80px);
			padding-left: 20px;
			@include font-set(1,16,2);
		}

		.career {
			display: inline-block;
			text-align: left;
			width: -webkit-calc(100% - 80px);
			width: calc(100% - 80px);
			padding-left: 20px;
			@include font-set(5,14,2);
		}

		.introduce {
			display: inline-block;
			text-align: left;
			width: -webkit-calc(100% - 80px);
			width: calc(100% - 80px);
			padding-left: 20px;
			@include font-set(2,14,2);
		}

	}

	.contents{
		.content {
			border-bottom : 1px solid #c0c0c0 ;
			margin: 20px;
		}
		li:last-child {
			border-bottom : 0px solid #c0c0c0 ;
		}
	}


}

.detail.photo.shops{
	box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
	margin-top:22px;
	text-align:left;
	background-color: white;
    border: 1px solid #c0c0c0;


	.bx-wrapper .bx-viewport {
	    -moz-box-shadow: inherit ;
	    -webkit-box-shadow:inherit ;
	    box-shadow: inherit ;
	}
	.bx-pager{
		display:none;
	}
	.title{
		@include font-set(1,20,2);
		margin:12px;
		border-bottom: 1px solid #c0c0c0;
	    padding-bottom: 11px;
	}

	.contents{
	    margin-left: 20px;
	    margin-right: 20px;
		height:100%;
	}

	.photo-title{
		font-weight: bold;
		@include font-set(1,16,2);
	    margin-top:22px;
	}

	.description{
		@include font-set(1,14,2);
	    margin-bottom:22px;
		width: 95%;
	}

	.image{
		height: 283px;
		width: 283px;
	    text-align: center;
	    overflow: hidden;
	    position: relative;
	    margin: 0 auto;


		@include mqSP {
			width: 84%;
	    	margin: 0 auto;
			width: 77%;
			margin: 0 8%;

		}
	    img{
	    position: absolute;
    	min-width: 100%;
	    min-height: 100%;
	    left: 50%;
	    top: 50%;
	    width: auto;
	    -webkit-transform: translate3d(-50%, -50%, 0);
	       -moz-transform: translate3d(-50%, -50%, 0);
	             -ms-transform: translate(-50%, -50%);
	            transform: translate3d(-50%, -50%, 0);
	    }
	}
}


.detail.photo.shops.list{
	display: inline-block;
    padding: 26px;
    width: 48%;
	min-width:330px;
	.contents{
	    margin-left: 0px;
	    margin-right: 0px;
	}

	@include mqSP {
	    width: 100%;
	}
}

.detail.feature.shops {
	box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
	margin-top: 22px;
	text-align: left;
	background-color: white;
	border: 1px solid #c0c0c0;

	.bx-wrapper .bx-viewport {
		-moz-box-shadow: inherit;
		-webkit-box-shadow: inherit;
		box-shadow: inherit;
		@include mqSP {
			height: 300px;
		}
	}
	.bx-pager {
		display: none;
	}
	.title {
		@include font-set(1, 20, 2);
		margin: 12px;
		border-bottom: 1px solid #c0c0c0;
		padding-bottom: 11px;
	}

	.contents {
		margin-left: 20px;
		margin-right: 20px;
		height: 100%;

		.photo-title {
			font-weight: bold;
			@include font-set(1, 16, 2);
			margin-top: 22px;
		}

		.description {
			@include font-set(1, 14, 2);
			margin-bottom: 22px;
			width: 95%;
		}

		.image {
			height: 283px;
			width: 77%;
			text-align: center;
			overflow: hidden;
			position: relative;
			margin: 0 auto;

			@include mqSP {
				width: 77%;
				margin: 0 8%;
				height: 170px;
			}
			img {
				position: absolute;
				min-width: 100%;
				//min-height: 100%;
				left: 50%;
				top: 50%;
				width: auto;
				-webkit-transform: translate3d(-50%, -50%, 0);
				-moz-transform: translate3d(-50%, -50%, 0);
				-ms-transform: translate(-50%, -50%);
				transform: translate3d(-50%, -50%, 0);
			}
		}
	}
}


.shop-recommends-list{
	margin-top: 22px;
	box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
	.items.shops .course {
		height: 70px;
		padding: 6px;
		width: 100%;
		margin: 6px 0 0 0;
		@include mqSP{
			height: auto;
			min-height: 80px;
		}
		.coupon{
			@include font-set(9,12,1);
			border-radius: 5px;
			background: red;
			max-width: 55px;
			text-align: center;
			line-height: 11px;
			padding: 5px 8px;
			float:left;
			span.wide{
				@include font-set(9,16,2);
				font-weight: bold;
			}
		}
		.price{
			margin-left: 65px;
			.normalprice{
				@include font-set(5,12,1);
				span.sellingprice{
					@include font-set(3,16,2);
					font-weight: bold;
					padding-left: 8px;
				}
				span.en{
					@include font-set(3,14,1);
				}
			}
			.coursetitle{
				@include font-set(1,14,2);
				font-weight: bold;
			}
		}
	}
}

.teacher{
	@extend .clearfix;
	padding:3%;
	.head{
		@extend .clearfix;
		width: 100%;
		border-bottom: 1px solid #c0c0c0;
		.human{
			position: relative;
			@include font-set(5,14,2);
			width: 50%;
			.thumbnail{
				@extend .thumbnail.circle;
				width: 85px;
				margin-right: 20px;
				float:left;
				@include mqSP{
					width: 85px;
				}
			}
		}
		.info{
			padding-bottom: 20px;
			width:220px;
	   		 text-align: left;
			float:left;

			@include mqSP{
				float: left;
    		width: 145px;
			}
			.name{
				@include font-set(1,16,2);
					font-weight: bold;
					margin-bottom: 10px;
				@include mqSP {
					@include font-set(1,14,2);
					margin-bottom:0;
				}
			}
			.advice{
				padding-right: 20px;
				@include font-set(5,12,1);
			}
			.thanks{
				@include font-set(5,12,1);
				img.clover {
					width: 12px;
		    		padding-bottom: 2px;
				}
			}
		}
	}

	.buttons{
		display:inline-block;
		float:right;
		@include mqSP {
			width: 100%;
    	padding-bottom: 20px;
		}
		.button{
			width:140px;
			@include mqSP {
				 width: 250px;
			}
		}
	}
	.buttons-t{
		display:block;
		text-align: center;
		padding-top:12px;
		.button{
			width:240px;
		}
	}

	.shopinfo{
		float:left;
		padding-left: 15px;
		text-align: left;
		border-left: 1px solid #c0c0c0;
		width: 210px;
		width: 243px;

		@include mqSP {
			width: 210px;
		}
		.name{
			@include font-set(1,14,2);
			font-weight: bold;
			@include mqSP {
				@include font-set(1,12,2);
				margin-bottom: 0
			}
		}
		.address{
			@include font-set(5,12,1);
			@include mqSP {
				@include font-set(5,12,1);
			}
		}
	}

	.button{
	    @extend .button.pattern17 ;
	    display:inline-block;
	    line-height:40px;
	    height:40px;
	    margin-top:0px;
	    @include base-color(2);
		@include mqSP {
			line-height:38px;
	   		height:35px;
			width: 140px;
		}
	}
	.button.clover{
 	 @extend .button.pattern17.g ;
 	 text-align:center;
 	 position : relative;
	  img {
	      width: 35px;
		    padding-left: 4px;
		    padding-right: 7px;
		    position: absolute;
		    top: 9px;
		    left: 50px;
	  }
	  background: #7fbe25;
	  color:white;
	}

	ul.category{
		@extend .clearfix;
		width:100%;
		@extend .ellipsis;
		height:22px;
		> li {
			float:left;
				@include font-set(5,12,1);
				@include mqSP {
					@include font-set(5,12,1);
			}
			&:after{
				content: "/";
			}
			&:first-child{
				&:before{
					content: "【";
				}
			}
			&:last-child{
				&:after{
					content: "】";
				}
			}
		}
	}
}

#marker-data{
	position:absolute;
	top:0;
	left:0;
	width: 100%;
	.map.shops{
		width:100%;
		height:120px;
		background-color: #5b584f;
		.image-area{
			width:200px;
			overflow: hidden;
		    width: 100px;/* トリミングしたい枠の幅 */
		    height: 100px;/* トリミングしたい枠の高さ */
		    position: relative;
				float:left;
				margin-top: 10px;
    		margin-left: 20px;
				margin-right: 20px;

			img{
				position: absolute;
				width: 250px;
				  top: 50%;
				  left: 50%;
				  -webkit-transform: translate(-50%, -50%);
				  -ms-transform: translate(-50%, -50%);
				  transform: translate(-50%, -50%);
			}
		}
		p{
			float:left;
			@include font-set(9,18,2);
			border-bottom: 1px solid white;
			margin-bottom: 5px;
			width: calc(100% - 158px);
			text-align: left;
			padding-top: 15px;
			padding-bottom: 5px;
			height: 47px;
		    overflow: hidden;
		    margin-bottom: 22px;

		}
		ul.category{
			@extend .clearfix;
			width: 110px;
			@extend .ellipsis;
			height:22px;
			> li {
				float:left;
				@include font-set(9,14,1);

				&:after{
					content: "/";
				}
				&:last-child{
					&:after{
						content: "";
					}
				}
			}
		}
		ul.star{
			float: left;
			li.number{
				span{
					@include font-set(9,14,2)
				}
			}
		}
	}
}


.n.buttons-t{
	.button{
		@extend .button.pattern17.g ;
		text-align:center;
		background: #7fbe25;
		color:white;
		display:block;
		text-align: center;
		font-size:9px;
		width:120px;
		line-height: 30px;
    	height: 31px;

	}
}


.detail.near.shops{
	margin-top:22px;
	text-align:left;
	background-color:transparent;

	width: 100%;
    max-width: 100%;

    height:auto;
    min-height:auto;
    padding:0;
    @extend .clearfix;
    margin: 22px 0 0 0;


    .page-title{
		@extend .clearfix;
        color: #50463c;
	    font-size: 20px;
	    font-size: 2rem;
	    line-height: 32px;
	    line-height: 3.2rem;
		border: 1px solid silver;
		padding: 10px;
		margin-bottom: 25px;
	    ul li {
	    	float:left;
	    }
    }

	.item{
		padding: 10px 12px;
		background: transparent;
		border-bottom: 1px solid #c0c0c0;
		padding-bottom: 8px;
		.title {
			display:inline-block;
			width:110px;
			@extend .clearfix;
			@include font-set(1,14,2);
		}
		.info{
			display:inline-block;
			width: -webkit-calc(100% - 130px);
			width: calc(100% - 130px);
			@include font-set(1,12,2);
		}
		&:last-child{
			border-bottom: 0px solid #c0c0c0;
		}
	}
}
ul.sp-shop-menu{
	@extend .clearfix;
	background-color: #4C483F;
    padding: 30px 0;
    text-align: center;
    color: white;
    margin-left: -17px;
    margin-right: -17px;
    padding: 10px 10px;
    color: white;
    position: relative;
    line-height: 4.3rem;

	li{
		float:left;
    	width: 33%;
	    text-align: center;
	    font-size: 1.5rem;
	    color: white;
	    margin-left: 0px;
		a{
			color:white;
		}
	}

	li:nth-of-type(2) {
		border-right:1px solid white;
		border-left:1px solid white;
	}
}

	.image-area.bxslider.shopsliedersfeature.photo{
		height:100%;
	}
	.image-area.bxslider.shopsliedersphotos.photo{
		height:100%;
	}

li.slider-pattern2_dtl{
	overflow: hidden;
	position: relative;
}
li.slider-pattern2_dtl >img{
	position: absolute;
	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	left: 50%;
	top: 50%;
	-webkit-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0);
}

.slider-pattern1_dtl{
	overflow: hidden;
	position: relative;
	display:inline-block;
	width:100%;

}
.slider-pattern1_dtl >img{
	position: absolute;
	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	left: 50%;
	top: 50%;
	-webkit-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0);
}
.shop-recommends-list{
	@include mqSP {
		margin-left:10px;
		margin-right:10px;
	};

}
.items.shops.recommends{


	.info {
	//	width : -webkit-calc(100% - 100px) ;
	//	width : calc(100% - 100px) ;
	//	@extend .clearfix;
		min-height:180px;
	}
}

#shop-quepon{
	margin-top:30px;
}

.items.shops.recommends .category{
	width:auto;
}
