.icon{
    z-index: 200;
	font-style: normal;
	
	&.circle{
	    background-color: #c0c0c0;
	    border-radius: 50%;
	    color: white;
	    float: left;
	    width: 28px;
	    height: 28px;
	    text-align: center;
	    display: block;
	    vertical-align: middle;
	    padding-top: 5px;
		position: absolute;

		&.question{
		    background-color: #f1862b;
			&:after{
				content: "Q";

			    position: absolute;
			    top: 4px;
			    right: 2px;
			    left: 0;
			    bottom: 0;
			    display: table-column;
			    
			}
		}
		&.answer{
		    background-color: #7fbe25;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "A";
			}

		}
		&.rank1{
			background-color:#e6c62e;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "1";
			}

		}
		&.rank2{
			background-color:#bfbcac;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "2";
			}

		}
		&.rank3{
			background-color:#938e74;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "3";
			}

		}
		&.rank4{
			background-color:#e6c62e;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "4";
			}

		}
		&.rank5{
			background-color:#f1862b;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "5";
			}

		}
		&.rank6{
			background-color:#f1862b;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "6";
			}
		}
		&.rank7{
			background-color:#f1862b;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "7";
			}
		}
		&.rank8{
			background-color:#f1862b;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "8";
			}
		}
		&.rank9{
			background-color:#f1862b;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "9";
			}
		}
		&.rank10{
			background-color:#f1862b;
			&:after{
				position: absolute;
				top: 4px;
				right: 0;
				left: 0;
				bottom: 0;
				margin: 0 auto;

				content: "10";
			}
		}
	}

}
