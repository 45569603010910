ul.areas.thumbnail{
	@extend .inline-center;
	@extend .items;
	li {
		width: 125px;
		height: 145px;
		vertical-align: top;
		margin: 10px 3px;
		border-radius: 6px;
		box-shadow: 0px 2px 4px #aaa;
		p {
			padding: 4px 16px;
		}
		img {
			width: 125px;
			height: 115px;
			border-radius: 6px 6px 0 0;
		}
	}
}


ul.areas.ranking{
	@extend .inline-center;
	@extend .items;
	li {
		padding: 3px 12px;
	    box-shadow: 0px 2px 4px #aaa;
	    margin: 3px 2px;
	    border-radius: 3px;
	 	&:hover{
	 		background:rgba(241, 241, 241, 0.92);
	 	}   
	}
}