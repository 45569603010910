form{
  .form-row{
      @extend .clearfix; 
      width: 100%;
      position: relative;

      .form-row-section{
        @extend .clearfix;
        .form-row-area{
            position: relative;
            width: 33%;
            text-align: left;
            float: left;
        }
      }
  }
}

input ,textarea{
  border:0;
  padding:10px;
  font-size:1.3em;
  font-family:Arial, sans-serif;
  border:solid 1px #ccc;
  background-color:white;

  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

input:focus {
    border:solid 1px #EEA34A;
}

input[type="image"] {
  padding:0;
  margin:0;
  background-color:transparent;
  border : 0;
};

select {
    -moz-appearance: none;
    -webkit-appearance: none;
    padding:4px;
    background-color:white;
        border: 1px solid #c0c0c0;
}

.search-input-area{
  position:absolute;right:0;top:-12px;
  @include mqSP {
      position:relative;
      top:0;
  }
}

.keywordsearch{
    background: url("../images/common/search-k.png");
    margin-right: 10px;
    width: 25px;
    background-size: contain;
    bakcground-color: transparent;
    margin-top: 18px;
    padding-top: 0px;
    margin-left: -40px;
    background-repeat: no-repeat;
    border: 0;
    

}