/*
  全体にかかる 基本要素および、基本classのみを定義します
*/
:root{
  background-color: $main-bg-color;
  color: $main-font-color;
}

html{
  background-color: $main-bg-color;
  color: $main-font-color;
}


body {
  font-family: $font-family ;
  word-break: break-word;
  width:100%;
}

.overflow-lock{
  overflow:hidden !important;
}
.overflow-auto{
  overflow:auto;
}

.overflow-scroll{
  overflow:scroll;
}

.disp-none{
  display:none;  
}

.position-fixed{
  position:fixed;
}

.no-border{
  border:0px !important;
}

img.auto{
  width:auto;
  max-width:100%;
}

img.l{
  width:100%;
  max-width:100%;
}

.number{
  @include font-color(2);
  font-size: 120%;
}

.background-transparent{
  background: transparent !important;
}
