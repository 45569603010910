#signin_menu {
    background-color: white;
    position: absolute;
    width: 336px;
    z-index: 100000;
    border: 1px transparent;
    text-align: left;
    right: 0px;
    top: 47px;
    box-shadow: 0px 2px 4px #aaa;
    font-size:1.3rem;

    @include mqSP {
        top: 90px;
    }
    .title{
        text-align: center;
        border-bottom: 1px solid #c0c0c0;
        padding: 12px 0;
        font-size: 16px;
        margin-bottom: 20px;
    }

    img.fb {
        width: 90%;
        margin: 0 5%;
    }

    input {
        width:90% !important;
        margin: 0 5% !important;
    }

    .linker.bl{
        text-align: center;
        text-decoration: underline;
        width: 100%;
        display: inline-block;
        padding-bottom: 8px;
    }

    .button{
        margin: 6% 5%  !important;
        width: 90% !important;
    }

    .shop_login{
      border-bottom: 1px solid silver;
      padding-bottom:8px;
      a.link{
        text-decoration: underline;
        color: #3f48cc;
      }
    }
}

.auth{
    table {
            background:transparent;
            border: 0;
        tr{
            background:transparent;
            border: 0;
            &.border-bottom{
              border-bottom: 1px solid #c0c0c0;
            }
        }
        td{
            width: 100%;
            display: block;
            border-top: none;

            background:transparent;
            border: 0;

        }
    }
    .button{
        margin: 6% 5%  !important;
        width: 90% !important;
    }
    .register-mail{
      .title{
        @include font-set(2,14,2);
        font-weight: bold;
      }
      p{
        @include font-set(5,12,1);
        padding-top:20px;
      }
    }
    .register-mail-detail{
      background-color: white;
      margin: 0 5px;
      margin-bottom: 45px;
      box-shadow: -1px -1px 5px #bdb0b0, 1px 1px 5px #bdb0b0;
      padding-bottom: 19px;
      width: 500px;
      margin: 0 auto;
      margin-top:30px;
      margin-bottom: 30px;
      @include mqSP{
        width: 100%;
      };
      .title{
        @include font-set(5,14,1);
        text-align: center;
        border-bottom: 1px solid #c0c0c0;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .contents{
        @include font-set(5,12,1);
        text-align: left;
        padding-left: 50px;
        padding-top: 10px;
        @include mqSP{
          padding-left:25px;
        };
        span{
          @include font-set(1,12,2);
          font-weight: bold;
        }
        &.blue{
          color: blue;
        }
      }
    }
    .register-mail-error{
      border-bottom: 1px solid #c0c0c0;
      .title{
        @include font-set(5,16,2);
        text-align: center;
        font-weight: bold;
        border-top: 1px solid #c0c0c0;
        border-bottom: 1px solid #c0c0c0;
        padding-top:5px;
        padding-bottom:5px;
        margin-bottom: 10px;
      }
      .contents{
        @include font-set(5,12,1);
        text-align: left;
        padding-left: 50px;
        padding-bottom: 20px;
        span{
          @include font-set(1,12,2);
          font-weight: bold;
        }
      }
    }
    .contact{
      @include font-set(5,12,1);
      padding-top:20px;
      a {
        text-decoration: underline;
        color: blue;
      }
    }
    .forgot-password-title{
      @include font-set(1,16,2);
      font-weight: bold;
      padding-top:50px;
      padding-bottom: 20px;
    }
    .forgot-password-error{
      width: 80%;
      margin: 0 auto;
      .title{
        @include font-set(5,16,2);
        text-align: center;
        font-weight: bold;
        border-top: 1px solid #c0c0c0;
        border-bottom: 1px solid #c0c0c0;
        padding-top:5px;
        padding-bottom:5px;
        margin-bottom: 10px;
      }
      .contents{
        @include font-set(5,12,1);
        text-align: left;
        padding-left: 50px;
        padding-bottom: 20px;
        span{
          @include font-set(1,12,2);
          font-weight: bold;
        }
      }
    }

}
.mypage{
    @extend .clearfix;
    padding-top:10px;
    padding-bottom: 10px;
    background-color: white;
    .thumbnail{
            float:left;
            @extend .thumbnail.circle;
            width: 125px;
            padding-right: 30px;

            &.center{
              float:none;
              margin: 0 auto;
              background-color: inherit;
            }
    }
    .name{
            margin-top: 25px;
            span {
            @include font-set(1,14,2);
            }
    }
    .file_input{
      padding-top: 15%;
      label {
        background-color: white;
        padding: 6px;
        border-radius: 12px;
        cursor: pointer;
        border: 1px solid #c0c0c0;;
      }
    }
    ul.profile{
            > li {
              float:left;
              @include font-set(5,10,1);

              &:after{
        				content: "/";
        			}
        			&:last-child{
        				&:after{
        					content: "";
        				}
              }
            }
    }

  &.setting{
    margin: 0 auto;
    width: 50%;
    text-align: center;
  }
}

.inner{
  .contact-dtl{
    width: 90%;
    margin: 0 auto;
  }
}
