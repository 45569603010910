.banner{
	display:inline-block;
	img {
		width:100%;
		max-width:300px;
	}

    @include mqSP {
		margin-top:20px;
	}


	&.tel{
		width: 100%;
    	position: relative;
    	padding-top:15px;
    	cursor:pointer;

	    @include mqSP {
			width: 98%;
		}

    	.telshop{
    		background-color: #ef8638;
		    border: 0;
		    text-align: center;
		    width: 100%;

		    .telmark{
			    position: absolute;
			    left: -12px;
			    top: 0px;
			    width: 52px;
			    color: white;

			    @include mqSP {
			    	display:none;
				}
		    }

			.lineicon{
				position: absolute;
				right: 0px;
				top: 0px;
				margin-top: 0;
				width: auto;
				color: white;
				height: 100%;
			}

		    .title{
	    	    background-color: #ef8638;
			    padding: 10px 0 5px 0;
			    color: white;
			    font-size: 1.6rem;
				font-weight: bold;
		    }

		    .businessday{
		        background-color: #ef8638;
			    padding: 0px 0 4px 0;
			    color: white;
			    font-size: 1.3rem;
		    }

		    .telno{
			    background-color: white;
			    font-weight: bold;
			    font-size: 1.8rem;
			    padding: 13px;
			    display:none;

				#tel_number{
					margin-bottom: 5px;
				}
		    }

			.description{
				background-color: #3f3f3f;
				letter-spacing: 0.1em;
				color:white;
				font-size: 1.2rem;
				padding: 4px;
				border-radius: 20px;
			}
    	}

		&.sp-only{
			.telshop{
				position: relative;
			}
		}
	}


	&.tel.sp{
		display: none;
		@include mqSP {
			display:block;
		}
	}

}